import React from "react";
import ClientListCom from "../component/ClientListCom";
const ClientList = () => {
  return (
    <>
      <section className="clientViewMainSection p-0 m-0">
        <div className="container-fluid">
          <div className="row p-0 m-0">
            <div className="col-lg-12">
              <ClientListCom />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ClientList;
