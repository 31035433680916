import React from "react";
// import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import VisitorAddScreen from "../screens/VisitorAddScreen";
import SidebarMenu from "./SidebarMenu";

export default function VisitorAdd() {

  return (
    <section>
      <div
        className="container-fluid"
        style={{ overflow: "hidden", height: "100vh" }}
      >
        <div className="row p-0">
          <div className="col-lg-2 p-0">
            <SidebarMenu />
          </div>
          <div className="col-lg-10  p-0">
            <div className="row ">
              <div className="col-lg-12 p-0">
                {/* <Navbar /> */}
  
              </div>
              <div
                className="col-lg-12 "
                style={{ position: "relative", height: "100vh" }}
              >
                <VisitorAddScreen />
                {/* <VisitorViewTable /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
