import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import PinInput from "react-pin-input";
import InputField from "./InputField";
import { stateDistricts } from "./State";
import add from "../asset/Add.png";
import minus from "../asset/minus.png";
import { Button } from "bootstrap";
import Modal from "./Modal";
import { useLocation, useNavigate } from "react-router";
import axios from "axios";
import Alert from "../component/Alert";
import "react-datepicker/dist/react-datepicker.css";
import { useMutation, useQuery } from "react-query";
import AddressModal from "./AddressModal";
import wrongIcon from "../asset/close.png";

export default function ClientInformationCom() {
  const [alsoWhatApp, setAlsoWhatApp] = useState();
  const [alsoPermanent, setAlsoPermanent] = useState();
  const [mobileNumberData, setMobileNumberData] = useState();
  const [districts, setDistricts] = useState([]);
  const [amtError, setAmtError] = useState(false);
  const [showVisitorBtn, setShowVisitorBtn] = useState(false);

  console.log("showVisitorBtn", showVisitorBtn);

  const [clientDetails, setClientDetails] = useState(false);
  const { state } = useLocation();
  console.log("state", state);

  const [postAlert, setPostAlert] = useState();
  const navigate = useNavigate();
  const [putAlert, setPutAlert] = useState();
  const [isModalOpen, setModalOpen] = useState(false);
  const [showPermanentAdd, setShowPermanentAdd] = useState(null);
  const [currentField, setCurrentField] = useState(null); // Track which field triggered the modal
  const [modalValue, setModalValue] = useState(""); // Store modal input value
  const { control, handleSubmit, setValue, watch, register, getValues } =
    useForm({
      defaultValues: {
        userForm: [],

        referenceData: null,
        caseDetailsDate: null,
      },
    });
  const { fields, append, remove } = useFieldArray({
    name: "userForm",
    control,
  });
  const {
    userForm,
    caseDetailsTotalAmount,
    caseDetailsAmountReceived,
    caseDetailsBalancePending,
    CheckNumber,
    subjectMobileNumber,
    // subjectInTime,
    subjectReligion,
    subjectCategory,
    subjectSourceForm,
    CheckWhatsappNumber,
    CheckEmailId,
    subjectNatureOfClose,
    SubjectAddress,
    natureOfCareDetails,
    referenceConfReport,
  } = watch();

  const userData = sessionStorage.getItem("userdata");
  console.log("CheckNumber", CheckNumber);

  const fetchingData = async () => {
    return await axios.get(`https://api.2kvirtualworld.com/DADEV/newvisitors`);
  };
  // Fetch visitor data using React Query
  const { data: visitorDetails, refetch } = useQuery(
    "fetchingData",
    fetchingData,
    {
      onSuccess: (data) => {
        // console.log('Visitor data fetched:', data?.data);
      },
    }
  );
  const visitorData = visitorDetails?.data || [];
  // console.log("visitorData", visitorData);

  const clientNumberData = () => {};
  useEffect(() => {
    const CheckClientNumber = visitorData?.find((data) => {
      const isMobileMatch =
        data?.mobileNumber && Number(data.mobileNumber) === Number(CheckNumber);
      const isWhatsappMatch =
        data?.whatsapp && Number(data.whatsapp) === Number(CheckWhatsappNumber);
      const isEmailMatch =
        data?.email && String(data.email) === String(CheckEmailId);

      return isMobileMatch || isWhatsappMatch || isEmailMatch;
    });
    console.log("alsoPermanent", alsoPermanent, CheckClientNumber);
    if (CheckClientNumber?.length !== 0) {
      setClientDetails(CheckClientNumber);
    }
    // else if (CheckClientNumber?.length === 0) {
    //   setAlsoPermanent("value");
    // }
    if (clientDetails) {
      console.log("DATA------>", CheckClientNumber);
      setValue("clientName", clientDetails && clientDetails?.name);
      setValue("fatherName", clientDetails && clientDetails?.fatherName);
      setValue("dob", clientDetails && clientDetails?.dob);
      setValue("age", clientDetails && clientDetails?.age);
      setValue("mobileNumber", clientDetails && clientDetails?.mobileNumber);
      setValue(
        "clientAddress",
        clientDetails && clientDetails?.temporaryAddress
      );
      setValue(
        "alternativeMobileNumber",
        clientDetails && clientDetails?.alternativeNumber
      );
      setValue("email", clientDetails && clientDetails?.email);
      setAlsoWhatApp(true);
    }
    console.log("showVisitorBtn", clientDetails, showVisitorBtn);
  }, [CheckNumber, clientDetails, CheckWhatsappNumber, CheckEmailId]);

  // Handle opening modal on focus
  const handleFocus = (fieldName, labelName, currentValue = "") => {
    setCurrentField({ fieldName, labelName, value: currentValue });
    setModalOpen(true); // Open modal on focus
  };

  // Save the updated value from the modal and close it
  const handleSave = (value) => {
    if (currentField) {
      setValue(currentField?.fieldName, value || ""); // Update form field with modal input
    }
    setModalOpen(false);
    setCurrentField(null);
  };

  const Amount = Number(caseDetailsTotalAmount) || 0;
  const balanceAmt = Number(caseDetailsAmountReceived) || 0;
  const amtReceived = Number(caseDetailsAmountReceived) || 0;
  const calclulateAmt = Amount - balanceAmt;

  const addAmt =
    Number(state?.caseDetails?.balancePending) +
    Number(caseDetailsAmountReceived);
  useEffect(() => {
    if (state) {
      if (balanceAmt === 0) {
        setValue("caseDetailsBalancePending", " ");
        setValue("caseDetailsStatus", "pending");
      } else if (
        Amount ==
        Number(state?.caseDetails?.amountReceived.reduce((a, b) => a + b)) +
          Number(balanceAmt)
      ) {
        setValue("caseDetailsBalancePending", 0);
        setValue("caseDetailsStatus", "completed");
      } else if (
        Amount -
          Number(state?.caseDetails?.amountReceived.reduce((a, b) => a + b)) +
          Number(balanceAmt) !==
        0
      ) {
        // alert("AAASSA")
        console.log(
          "LLLLLL",
          state?.caseDetails?.amountReceived.reduce((a, b) => a + b)
        );

        let Sum =
          Number(state?.caseDetails?.amountReceived.reduce((a, b) => a + b)) +
          Number(balanceAmt);
        setValue("caseDetailsBalancePending", Number(Amount) - Sum);
        setValue("caseDetailsStatus", "pending");
      }
    } else {
      if (Amount == Number(balanceAmt)) {
        setValue("caseDetailsBalancePending", 0);
        setValue("caseDetailsStatus", "completed");
      } else if (Number(balanceAmt) == 0) {
        // alert("AAASSA")
        // let Sum = Number(state?.caseDetails?.amountReceived) + Number(balanceAmt)
        setValue("caseDetailsBalancePending", Number(balanceAmt));
        setValue("caseDetailsStatus", "pending");
      } else if (Number(Amount) - Number(balanceAmt) !== 0) {
        setValue(
          "caseDetailsBalancePending",
          Number(Amount) - Number(balanceAmt)
        );
        setValue("caseDetailsStatus", "pending");
      }
      if (Amount < amtReceived) {
        setValue("caseDetailsBalancePending", 0);
        setValue("caseDetailsStatus", "completed");
        setAmtError("Amount received more than total Amount");
      }
    }
  }, [
    state,
    balanceAmt,
    Amount,
    calclulateAmt,
    caseDetailsBalancePending,
    caseDetailsTotalAmount,
  ]);

  const { mutate, isLoading } = useMutation(
    (postData) =>
      axios.post(
        "https://api.2kvirtualworld.com/DADEV/visitors/save",
        postData
      ),
    {
      onSuccess: (data) => {
        // setPostAlert(true);
        navigate("/clientList");
      },
      onError: (error) => {
        console.error("Mutation failed:", error);
      },
    }
  );
  const upData = useMutation(
    (postData) =>
      axios.put(
        `https://api.2kvirtualworld.com/DADEV/visitors/update/${state?.id}`,
        postData
      ),
    {
      // You can define callbacks for different stages of the mutation
      onSuccess: () => {
        // setPutAlert(true);
        navigate("/clientList");
      },
      onError: (error) => {
        console.error("Mutation failed:", error);
      },
    }
  );

  const onSubmit = (data) => {
    const formData = {
      subjectDetails: {
        subjectName: data?.subjectName,
        subjectFatherName: data?.subjectFatherName,
        subjectDob: data?.subjectDob,
        subjectAge: data?.subjectAge,
        subjectReligion: data?.subjectReligion,
        subjectOtherReligion: data?.subjectOtherReligion,
        subjectCaste: data?.subjectCaste,
        subjectMobileNumber: data?.subjectMobileNumber,
        subjectAlternativeNumber: data?.subjectAlternativeNumber,
        subjectWhatsapp: data?.subjectWhatsapp,
        subjectEmail: data?.subjectEmail,
        subjectCategory: data?.subjectCategory,
        subjectOtherCategory: data?.subjectOtherCategory,
        subjectSourceForm: data?.subjectSourceForm,
        subjectOtherSourceForm: data?.subjectOtherSourceForm,
        vehicleDetails: data?.vehicleDetails,
        subjectAdditionalStatus: data?.subjectAdditionalStatus,
        subjectTemporaryAddress: data?.subjectTemporaryAddress,
        subjectOfficeAddress: data?.subjectOfficeAddress,
        subjectOfficeAddNo: data?.subjectOfficeAddNo,
        subjectOfficeAddEmail: data?.subjectOfficeAddEmail,
        subjectOfficeAdditionalDetails: data?.subjectOfficeAdditionalDetails,
        subjectPermanentAddress: data?.subjectPermanentAddress,
        subjectPermanentAddNo: data?.subjectPermanentAddNo,
        subjectPermanentAddEmail: data?.subjectPermanentAddEmail,
        subjectPermanentAdditionalDetails:
          data?.subjectPermanentAdditionalDetails,
      },
      // subjectDetailstwo: subjectDetails,
      clientInformation: {
        clientName: data?.clientName,
        age: data?.age,
        mobileNumber: data?.mobileNumber,
        alternativeMobileNumber: data?.alternativeMobileNumber,
        email: data?.email,
        address: data?.clientAddress,
        fatherName: data?.fatherName,
        marrielStatus: data?.clientMarried,
        guardianOrParent: data?.clientGuardian,
      },
      caseDetails: {
        caseNumber: data?.caseDetailsName,
        natureOfCareTitle: data?.caseDetailsNatureOfCaseTitle,
        natureOfCareClientReq: data?.natureOfCaseClientRequirement,
        date: data?.caseDetailsDate
          ? moment(data.caseDetailsDate).format("DD/MM/YYYY")
          : undefined,
        natureOfCareDetails: data?.natureOfCareDetails,
        totalAmount: data?.caseDetailsTotalAmount,
        chiefComment: data?.chiefComment,
        dateOfRegards: data?.subjectDateRegards
        ? moment(data?.dateOfRegards).format("DD/MM/YYYY")
        : undefined, 
        dateOfClosed: data?.subjectDateClose
        ? moment(data?.dateOfClosed).format("DD/MM/YYYY")
        : undefined,

        amountReceived:
          state?.caseDetails?.amountReceived == undefined
            ? [data?.caseDetailsAmountReceived]
            : state?.caseDetails?.amountReceived &&
              state?.caseDetails?.amountReceived.concat(
                Number(data?.caseDetailsAmountReceived)
              ),
        balancePending:
          state?.caseDetails?.balancePending == undefined
            ? [data?.caseDetailsBalancePending]
            : state?.caseDetails?.balancePending &&
              state?.caseDetails?.balancePending.concat(
                Number(data?.caseDetailsBalancePending)
              ),
        status:
          state?.caseDetails?.status == undefined
            ? [data?.caseDetailsStatus]
            : state?.caseDetails?.status &&
              state?.caseDetails?.status.concat(data?.caseDetailsStatus),
        amountDate:
          state?.caseDetails?.amountDate == undefined
            ? [data?.caseDetailsAmountDate]
            : state?.caseDetails?.amountDate &&
              state?.caseDetails?.amountDate.concat(
                data?.caseDetailsAmountDate
              ),
        paymentType:
          state?.caseDetails?.paymentType == undefined
            ? [data?.caseDetailsPaymentType]
            : state?.caseDetails?.paymentType &&
              state?.caseDetails?.paymentType.concat(
                data?.caseDetailsPaymentType
              ),
      },
      referenceDetails: {
        reference: data?.reference,
        nameOfLos: data?.referenceNameOfLos,
        date: data?.referenceData
          ? moment(data?.referenceData).format("DD/MM/YYYY")
          : undefined,
        fileNumber: data?.referenceFileNumber,
        reasonForClosure: data?.reasonForClosure,
        confReport: data?.referenceConfReport,
      },
    };
    console.log("formData", formData);

    setPostAlert(formData);
    setAlsoWhatApp(true);
  };

  function calculateAge(birthDate, index) {
    console.log("birthDate", moment().diff(moment(birthDate), "years"), index);
    setValue("subjectAge", moment().diff(moment(birthDate), "years") || "");
  }

  const mobileCheckBox = (e) => {
    const checked = e.target.checked;

    if (checked) {
      console.log("checked", checked, subjectMobileNumber);
      setValue("subjectWhatsapp", subjectMobileNumber);
      setAlsoWhatApp(true);
    } else {
      setValue("subjectWhatsapp", "");
      setAlsoWhatApp(false);
    }
  };

  // function calculateAge(birthDate, index) {
  //     console.log("birthDate", moment().diff(moment(birthDate), "years"), index);
  //     setValue("age", moment().diff(moment(birthDate), "years") || "");
  //     if (birthDate && index === 0) {
  //       userForm?.map((items) => {
  //         console.log("welcome");
  //         if (index === 0) {
  //           setValue(
  //             `userForm.${index}.age`,
  //             moment().diff(moment(birthDate), "years") || ""
  //           );
  //         }
  //       });
  //     } else if (birthDate && index) {
  //       userForm?.map((items) => {
  //         // console.log("welcome");
  //         if (index === 0) {
  //           setValue(
  //             `userForm.${index}.age`,
  //             moment().diff(moment(birthDate), "years") || ""
  //           );
  //         } else {
  //           setValue(
  //             `userForm.${index}.age`,
  //             moment().diff(moment(birthDate), "years") || ""
  //           );
  //         }
  //       });
  //     }
  //     // return moment().diff(moment(birthDate), "years");
  //   }

  const allDistricts = Object.values(stateDistricts).flat();
  useEffect(() => {
    userForm?.map((ele, i) => {
      console.log("LLDDDF", ele);

      setDistricts(stateDistricts[ele?.state] || []);
    });
  }, [userForm]);

  // console.log("state", state);
  useEffect(() => {
    if (state && state) {
      // removeSubject();
      // console.log("state?.userForm", state?.userForm);

      setValue("subjectName", state?.
        subjectDetails?.subjectName);
      setValue("subjectFatherName", state?.subjectDetails?.subjectFatherName || "");
      setValue("subjectDob", state?.subjectDetails?.subjectDob || "");
      setValue("subjectAge", state?.subjectDetails?.subjectAge || "");
      setValue("subjectReligion", state?.subjectDetails?.subjectReligion || "");
      setValue("subjectOtherReligion", state?.subjectDetails?.subjectOtherReligion || "");
      setValue("subjectCaste", state?.subjectDetails?.subjectCaste || "");
      setValue("subjectMobileNumber", state?.subjectDetails?.subjectMobileNumber || "");
      setValue(
        "subjectAlternativeNumber",
        state?.subjectDetails?.subjectAlternativeNumber || ""
      );
      setValue("subjectWhatsapp", state?.subjectDetails?.subjectWhatsapp || "");
      setValue("subjectEmail", state?.subjectDetails?.subjectEmail || "");
      setValue("subjectCategory", state?.subjectDetails?.subjectCategory || "");
      setValue("subjectOtherCategory", state?.subjectDetails?.subjectOtherCategory || "");
      setValue("subjectSourceForm", state?.subjectDetails?.subjectSourceForm || "");
      setValue("subjectOtherSourceForm", state?.subjectDetails?.subjectOtherSourceForm || "");
      setValue("vehicleDetails", state?.subjectDetails?.vehicleDetails || "");
      setValue("subjectAdditionalStatus", state?.subjectDetails?.subjectAdditionalStatus || "");
      setValue("subjectTemporaryAddress", state?.subjectDetails?.subjectTemporaryAddress || "");
      setValue("subjectOfficeAddress", state?.subjectDetails?.subjectOfficeAddress || "");
      setValue("subjectOfficeAddNo", state?.subjectDetails?.subjectOfficeAddNo || "");
      setValue("subjectOfficeAddEmail", state?.subjectDetails?.subjectOfficeAddEmail || "");
      setValue(
        "subjectOfficeAdditionalDetails",
        state?.subjectDetails?.subjectOfficeAdditionalDetails || ""
      );
      setValue("subjectPermanentAddress", state?.subjectDetails?.subjectPermanentAddress || "");
      setValue("subjectPermanentAddNo", state?.subjectDetails?.subjectPermanentAddNo || "");
      // setValue("vehicleDetails", state?.subjectDetails?.vehicleDetails || "");
      setValue(
        "subjectPermanentAddEmail",
        state?.subjectDetails?.subjectPermanentAddEmail || ""
      );
      setValue(
        "subjectPermanentAdditionalDetails",
        state?.subjectPermanentAdditionalDetails || ""
      );
      setAlsoPermanent(true)
      // clientInformation
      setValue("clientName", state?.clientInformation?.clientName);
      setValue("age", state?.clientInformation?.age);
      setValue("mobileNumber", state?.clientInformation?.mobileNumber);
      setValue("CheckNumber", state?.clientInformation?.mobileNumber);
      setValue(
        "alternativeMobileNumber",
        state?.clientInformation?.alternativeMobileNumber
      );
      setValue("email", state?.clientInformation?.email);
      setValue("clientAddress", state?.clientInformation?.address);
      setValue("fatherName", state?.clientInformation?.fatherName);
      setValue("clientMarried", state?.clientInformation?.marrielStatus);
      setValue("clientGuardian", state?.clientInformation?.guardianOrParent);
      // case details
      setValue("caseDetailsName", state?.caseDetails?.caseNumber);
      setValue(
        "caseDetailsNatureOfCaseTitle",
        state?.caseDetails?.natureOfCareTitle
      );
      setValue(
        "natureOfCaseClientRequirement",
        state?.caseDetails?.natureOfCareClientReq
      );
      setValue(
        "chiefComment",
        state?.caseDetails?.chiefComment
      );
      setValue(
        "caseDetailsDate",
        state?.caseDetails?.date !== null
          ? moment(state?.caseDetails?.date, "DD-MM-YYYY").format("MM/DD/YYYY")
          : ""
      );
      setValue(
        "subjectDateRegards",
        state?.caseDetails?.dateOfRegards !== null
          ? moment(state?.caseDetails?.dateOfRegards, "DD-MM-YYYY").format(
              "MM/DD/YYYY"
            )
          : ""
      );
      setValue(
        "subjectDateClose",
        state?.caseDetails?.dateOfClosed !== null
          ? moment(state?.caseDetails?.dateOfClosed, "DD-MM-YYYY").format(
              "MM/DD/YYYY"
            )
          : ""
      );
      setValue("natureOfCareDetails", state?.caseDetails?.natureOfCareDetails);
      setValue("caseDetailsTotalAmount", state?.caseDetails?.totalAmount);
      setValue("chiefComment", state?.caseDetails?.chiefComment);
      setValue("caseDetailsBalancePending", state?.caseDetails?.balancePending);
      setValue("caseDetailsStatus", state?.caseDetails?.status);
      setValue("caseDetailsPaymentType", state?.caseDetails?.paymentType);
      // referenceDetails
      console.log(
        "state?.caseDetails?.amountDate",
        state?.caseDetails?.amountDate[0] !== null
      );

      setValue("reference", state?.referenceDetails?.reference);
      setValue("referenceNameOfLos", state?.referenceDetails?.nameOfLos);
      setValue("caseDetailsAmountDate", new Date());

      setValue(
        "referenceData",
        state?.referenceDetails?.date !== null
          ? 
            moment(state?.referenceDetails?.date, "DD-MM-YYYY").format(
              "MM/DD/YYYY"
            )
          : ""
      );
      setValue("referenceFileNumber", state?.referenceDetails?.fileNumber);
      setValue(
        "reasonForClosure",
        state?.referenceDetails?.reasonForClosure
      );

      setValue("referenceConfReport", state?.referenceDetails?.confReport);
      // setValue("referenceCaseDetails", state?.referenceDetails?.
      //   referenceDetails
      //   );
    }
  }, [state, setValue]);

  return (
    <div className="mainContainerDiv" style={{ overflow: "scroll" }}>
      <section>
        <div className="headerContainer">
          <header className="visitorEntryHeader">Client Entry</header>
        </div>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="d-flex align-items-center py-4">
              {clientDetails ? (
                <div>
                  <div className="visitorEntryFormInput">
                    <div className="visitorEntryInputContainer">
                      <div>
                        <label className="visitorInfoLabelContainer">
                          Client Name
                        </label>
                      </div>
                      <Controller
                        control={control}
                        name="clientName"
                        render={(field) => (
                          <InputField
                            visiterInfoCss="visiterInfoCss"
                            {...field}
                            type="text"
                          />
                        )}
                      />
                    </div>

                    <div className="visitorEntryInputContainer">
                      <div>
                        <label className="visitorInfoLabelContainer">S/o</label>
                      </div>
                      <Controller
                        control={control}
                        name="fatherName"
                        render={(field) => (
                          <InputField
                            {...field}
                            visiterInfoCss="visiterInfoCss"
                            type="text"
                          />
                        )}
                      />
                    </div>
                    <div style={{ width: "18%", display: "flex" }}>
                      <div
                        className="visitorEntryInputContainer"
                        style={{ width: "78%", marginRight: "2%" }}
                      >
                        <div>
                          <label className="visitorInfoLabelContainer">
                            DOB
                          </label>
                        </div>
                        <Controller
                          control={control}
                          name="dob"
                          render={({ field }) => (
                            <DatePicker
                              dateFormat="dd/MM/yyyy"
                              className="visiterInfoCss rounded-start"
                              showYearDropdown
                              selected={field.value}
                              onChange={(date) => {
                                field.onChange(date);
                                calculateAge(date);
                              }}
                            />
                          )}
                        />
                      </div>
                      <div
                        className="visitorEntryInputContainer"
                        style={{ width: "20%" }}
                      >
                        <div>
                          <label className="visitorInfoLabelContainer">
                            Age
                          </label>
                        </div>
                        <Controller
                          control={control}
                          name="age"
                          render={(field) => (
                            <InputField
                              {...field}
                              visiterInfoCss="visiterInfoCss"
                              type="number"
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="visitorInfoMobile">
                      <div>
                        <label className="visitorInfoLabelContainer">
                          Mobile Number
                        </label>
                      </div>
                      <Controller
                        key={
                          alsoWhatApp
                            ? "whatsapp-synced"
                            : "whatsapp-independent"
                        }
                        control={control}
                        name="mobileNumber"
                        rules={{
                          minLength: {
                            value: 10,
                            message: "Mobile number must be 10 digits",
                          },
                          maxLength: {
                            value: 10,
                            message: "Mobile number must be 10 digits",
                          },
                          pattern: {
                            value: /^[0-9]{10}$/,
                            message:
                              "Please enter a valid 10-digit mobile number",
                          },
                        }}
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => (
                          <>
                            <PinInput
                              length={10}
                              initialValue={value || ""}
                              onChange={(val) => {
                                onChange(val);
                              }}
                              type="numeric"
                              style={{ width: "100%", borderRadius: "5px" }}
                              inputStyle={{
                                border: "1px solid gray",
                                backgroundColor: "#fff",
                                height: "40px",
                                width: "10%",
                                margin: "0px",
                                borderRadius: "5px",
                              }}
                              autoSelect
                              onComplete={(value, index) => {
                                console.log("onComplete", value);
                                setMobileNumberData(value);
                              }}
                            />
                            {error && (
                              <span
                                style={{
                                  color: "red",
                                  fontSize: "12px",
                                  marginTop: "5px",
                                }}
                              >
                                {error.message}
                              </span>
                            )}
                          </>
                        )}
                      />
                    </div>
                    <div className="visitorInfoMobile">
                      <div>
                        <label className="visitorInfoLabelContainer">
                          Alternative Number
                        </label>
                      </div>
                      <Controller
                        key={
                          alsoWhatApp
                            ? "whatsapp-synced"
                            : "whatsapp-independent"
                        }
                        control={control}
                        name="alternativeMobileNumber"
                        rules={{
                          minLength: {
                            value: 10,
                            message: "Mobile number must be 10 digits", // Error message if length is less than 10
                          },
                          maxLength: {
                            value: 10,
                            message: "Mobile number must be 10 digits", // Error message if length is more than 10
                          },
                          pattern: {
                            value: /^[0-9]{10}$/, // Ensure the input is numeric and exactly 10 digits
                            message:
                              "Please enter a valid 10-digit mobile number", // Custom error message
                          },
                        }}
                        render={({
                          field: { onChange, onBlur, value, ref },
                          fieldState: { error },
                        }) => (
                          <>
                            <PinInput
                              length={10}
                              initialValue={value || ""}
                              onChange={onChange}
                              type="numeric"
                              style={{ width: "100%", borderRadius: "5px" }}
                              inputStyle={{
                                border: "1px solid gray",
                                backgroundColor: "#fff",
                                height: "40px",
                                width: "10%",
                                margin: "0px",
                                borderRadius: "5px",
                              }}
                              autoSelect={true}
                            />

                            {error && (
                              <span
                                style={{
                                  color: "red",
                                  fontSize: "12px",
                                  marginTop: "5px",
                                }}
                              >
                                {error?.message}{" "}
                              </span>
                            )}
                          </>
                        )}
                      />
                    </div>

                    <div className="visitorEntryInputContainer">
                      <div>
                        <label className="visitorInfoLabelContainer">
                          E Mail
                        </label>
                      </div>
                      <Controller
                        control={control}
                        name="email"
                        render={(field) => (
                          <InputField
                            {...field}
                            visiterInfoCss="visiterInfoCss"
                            type="text"
                          />
                        )}
                      />
                    </div>
                    <div className="visitorEntryInputContainer">
                      <div>
                        <label className="visitorInfoLabelContainer">
                          Married/ Unmarried
                        </label>
                      </div>
                      {/* <Controller
                        control={control}
                        name="clientMarried"
                        render={(field) => (
                          <InputField
                            {...field}
                            visiterInfoCss="visiterInfoCss"
                            type="text"
                          />
                        )}
                      /> */}
                      <Controller
                        control={control}
                        name="clientMarried"
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <select
                            value={value}
                            className="visiterInfoCss py-2"
                            onChange={onChange}
                            onBlur={onBlur} // Added onBlur for better form validation handling
                            ref={ref} // Make sure the ref is passed down for proper field tracking
                          >
                            <option value=""> </option>
                            <option value="Married">Married</option>
                            <option value="Unmarried">Unmarried</option>
                          </select>
                        )}
                      />
                    </div>
                    <div className="visitorEntryInputContainer">
                      <div>
                        <label className="visitorInfoLabelContainer">
                          Relation
                        </label>
                      </div>

                      <Controller
                        control={control}
                        name="clientGuardian"
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <select
                            value={value}
                            className="visiterInfoCss py-2"
                            onChange={onChange}
                            onBlur={onBlur} // Added onBlur for better form validation handling
                            ref={ref} // Make sure the ref is passed down for proper field tracking
                          >
                            <option value=""> </option>
                            <option value="Guardian">Guardian</option>
                            <option value="Spouse">Spouse</option>
                            <option value="Parent">Parent</option>
                            <option value="Children">Children</option>
                            <option value="Friend">Friend</option>
                          </select>
                        )}
                      />
                    </div>
                    <div
                      className="visitorEntryInputContainer"
                      style={{ width: "38%", height: "160px" }}
                    >
                      <div>
                        <label className="visitorInfoLabelContainer">
                          Address
                        </label>
                      </div>
                      <Controller
                        control={control}
                        name="clientAddress"
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <textarea
                            style={{ height: "40px", width: "100%" }}
                            value={value}
                            className="visiterInfoCss  py-2"
                            onChange={onChange}
                          ></textarea>
                        )}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="d-flex gap-3">
                  <div className="ms-2 visitorInfoMobile">
                    <label className="visitorInfoLabelContainer">
                      Visitor Mobile Number
                    </label>
                    <Controller
                      control={control}
                      name="CheckNumber"
                      rules={{
                        minLength: {
                          value: 10,
                          message: "Mobile number must be 10 digits", // Error message when length is less than 10
                        },
                        maxLength: {
                          value: 10,
                          message: "Mobile number must be 10 digits", // Error message when length is more than 10
                        },
                        pattern: {
                          value: /^[0-9]{10}$/,
                          message:
                            "Please enter a valid 10-digit mobile number", // Ensure it's numeric
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <PinInput
                          length={10}
                          initialValue={value}
                          value={value || ""}
                          onChange={(val) => onChange(val)}
                          type="numeric"
                          style={{ width: "100%", borderRadius: "5px" }}
                          inputStyle={{
                            border: "1px solid gray",
                            backgroundColor: "#fff",
                            height: "40px",
                            width: "10%",
                            margin: "0px",
                            borderRadius: "5px",
                          }}
                          onComplete={(val) => {
                            clientNumberData(val);
                            console.log("completed");
                          }}
                          autoSelect
                        />
                      )}
                    />
                    {alsoPermanent === "Value" && <p>CheckClientNumber</p>}
                  </div>
                  <div className="ms-2 visitorInfoMobile">
                    <label className="visitorInfoLabelContainer">
                      Visitor Whatsapp Number
                    </label>
                    <Controller
                      control={control}
                      name="CheckWhatsappNumber"
                      rules={{
                        minLength: {
                          value: 10,
                          message: "Mobile number must be 10 digits", // Error message when length is less than 10
                        },
                        maxLength: {
                          value: 10,
                          message: "Mobile number must be 10 digits", // Error message when length is more than 10
                        },
                        pattern: {
                          value: /^[0-9]{10}$/,
                          message:
                            "Please enter a valid 10-digit mobile number", // Ensure it's numeric
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <PinInput
                          length={10}
                          initialValue={value}
                          value={value || ""}
                          onChange={(val) => onChange(val)}
                          type="numeric"
                          style={{ width: "100%", borderRadius: "5px" }}
                          inputStyle={{
                            border: "1px solid gray",
                            backgroundColor: "#fff",
                            height: "40px",
                            width: "10%",
                            margin: "0px",
                            borderRadius: "5px",
                          }}
                          onComplete={(val) => {
                            clientNumberData(val);
                            console.log("completed");
                          }}
                          autoSelect
                        />
                      )}
                    />
                    {alsoPermanent === "Value" && <p>CheckClientNumber</p>}
                  </div>
                  <div className="ms-2 visitorInfoMobile">
                    <label className="visitorInfoLabelContainer">
                      Visitor Mail Id Number
                    </label>
                    <Controller
                      control={control}
                      name="CheckEmailId"
                      render={(field) => (
                        <InputField
                          visiterInfoCss="visiterInfoCss"
                          {...field}
                          type="email"
                        />
                      )}
                    />
                    {alsoPermanent === "Value" && <p>CheckClientNumber</p>}
                  </div>
                </div>
              )}
            </div>
            <div>
              <div className="headerContainer">
                <header className="visitorEntryHeader"> Subject Details</header>
              </div>
              <div className="visitorEntryFormInput">
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">Name</label>
                  </div>
                  <Controller
                    control={control}
                    name="subjectName"
                    render={(field) => (
                      <InputField
                        visiterInfoCss="visiterInfoCss"
                        {...field}
                        type="text"
                      />
                    )}
                  />
                </div>
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">S/o</label>
                  </div>
                  <Controller
                    control={control}
                    name="subjectFatherName"
                    render={(field) => (
                      <InputField
                        {...field}
                        visiterInfoCss="visiterInfoCss"
                        type="text"
                      />
                    )}
                  />
                </div>
                <div style={{ width: "18%", display: "flex" }}>
                  <div
                    className="visitorEntryInputContainer"
                    style={{ width: "78%", marginRight: "2%" }}
                  >
                    <div>
                      <label className="visitorInfoLabelContainer">DOB</label>
                    </div>
                    <Controller
                      control={control}
                      name="subjectDob"
                      render={({ field }) => (
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          className="visiterInfoCss rounded-start"
                          showYearDropdown
                          selected={field.value}
                          onChange={(date) => {
                            field.onChange(date);
                            calculateAge(date);
                          }}
                        />
                      )}
                    />
                  </div>
                  <div
                    className="visitorEntryInputContainer"
                    style={{ width: "20%" }}
                  >
                    <div>
                      <label className="visitorInfoLabelContainer">Age</label>
                    </div>
                    <Controller
                      control={control}
                      name="subjectAge"
                      render={(field) => (
                        <InputField
                          {...field}
                          visiterInfoCss="visiterInfoCss"
                          type="text"
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Religion
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="subjectReligion"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <select
                        value={value}
                        className="visiterInfoCss py-2"
                        onChange={onChange}
                        onBlur={onBlur} // Added onBlur for better form validation handling
                        ref={ref} // Make sure the ref is passed down for proper field tracking
                      >
                        <option value=""> </option>
                        <option value="Christianity">Christianity</option>
                        <option value="Islam">Islam</option>
                        <option value="Hinduism">Hinduism</option>
                        <option value="Other">Other</option>
                      </select>
                    )}
                  />
                </div>
                {subjectReligion === "Other" && (
                  <div className="visitorEntryInputContainer">
                    <div>
                      <label className="visitorInfoLabelContainer">
                        Other Religion
                      </label>
                    </div>
                    <Controller
                      control={control}
                      name="subjectOtherReligion"
                      render={(field) => (
                        <InputField
                          {...field}
                          visiterInfoCss="visiterInfoCss"
                          type="text"
                        />
                      )}
                    />
                  </div>
                )}
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">Caste</label>
                  </div>
                  <Controller
                    control={control}
                    name="subjectCaste"
                    render={(field) => (
                      <InputField
                        {...field}
                        visiterInfoCss="visiterInfoCss"
                        type="text"
                      />
                    )}
                  />
                </div>
                <div className="visitorInfoMobile">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Mobile Number
                    </label>
                  </div>
                  <Controller
                    key={
                      alsoWhatApp ? "whatsapp-synced" : "whatsapp-independent"
                    }
                    control={control}
                    name="subjectMobileNumber"
                    rules={{
                      minLength: {
                        value: 10,
                        message: "Mobile number must be 10 digits",
                      },
                      maxLength: {
                        value: 10,
                        message: "Mobile number must be 10 digits",
                      },
                      pattern: {
                        value: /^[0-9]{10}$/,
                        message: "Please enter a valid 10-digit mobile number",
                      },
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <PinInput
                          length={10}
                          initialValue={value || ""}
                          onChange={(val) => {
                            onChange(val);

                            if (alsoWhatApp && val?.length === 10) {
                              setValue("whatsapp", val);
                            }
                          }}
                          type="numeric"
                          style={{ width: "100%", borderRadius: "5px" }}
                          inputStyle={{
                            border: "1px solid gray",
                            backgroundColor: "#fff",
                            height: "40px",
                            width: "10%",
                            margin: "0px",
                            borderRadius: "5px",
                          }}
                          autoSelect
                          onComplete={(value, index) => {
                            console.log("onComplete", value);
                            setMobileNumberData(value);
                          }}
                        />
                        {error && (
                          <span
                            style={{
                              color: "red",
                              fontSize: "12px",
                              marginTop: "5px",
                            }}
                          >
                            {error.message}
                          </span>
                        )}
                      </>
                    )}
                  />
                </div>
                <div className="visitorInfoMobile">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Alternative Number
                    </label>
                  </div>
                  <Controller
                    key={
                      alsoWhatApp ? "whatsapp-synced" : "whatsapp-independent"
                    }
                    control={control}
                    name="subjectAlternativeNumber"
                    rules={{
                      minLength: {
                        value: 10,
                        message: "Mobile number must be 10 digits", // Error message if length is less than 10
                      },
                      maxLength: {
                        value: 10,
                        message: "Mobile number must be 10 digits", // Error message if length is more than 10
                      },
                      pattern: {
                        value: /^[0-9]{10}$/, // Ensure the input is numeric and exactly 10 digits
                        message: "Please enter a valid 10-digit mobile number", // Custom error message
                      },
                    }}
                    render={({
                      field: { onChange, onBlur, value, ref },
                      fieldState: { error },
                    }) => (
                      <>
                        <PinInput
                          length={10}
                          initialValue={value || ""}
                          onChange={onChange}
                          type="numeric"
                          style={{ width: "100%", borderRadius: "5px" }}
                          inputStyle={{
                            border: "1px solid gray",
                            backgroundColor: "#fff",
                            height: "40px",
                            width: "10%",
                            margin: "0px",
                            borderRadius: "5px",
                          }}
                          autoSelect={true}
                        />

                        {error && (
                          <span
                            style={{
                              color: "red",
                              fontSize: "12px",
                              marginTop: "5px",
                            }}
                          >
                            {error?.message}{" "}
                          </span>
                        )}
                      </>
                    )}
                  />
                </div>
                <div className="visitorInfoMobile">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      WhatsApp Number
                    </label>
                  </div>
                  <Controller
                    key={
                      alsoWhatApp ? "whatsapp-synced" : "whatsapp-independent"
                    } // Unique key for re-rendering
                    control={control}
                    name="subjectWhatsapp"
                    rules={{
                      minLength: {
                        value: 10,
                        message: "Mobile number must be 10 digits", // Error message when length is less than 10
                      },
                      maxLength: {
                        value: 10,
                        message: "Mobile number must be 10 digits", // Error message when length is more than 10
                      },
                      pattern: {
                        value: /^[0-9]{10}$/,
                        message: "Please enter a valid 10-digit mobile number", // Ensure it's numeric
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <PinInput
                        length={10}
                        initialValue={value}
                        value={value || ""}
                        onChange={(val) => onChange(val)}
                        type="numeric"
                        style={{ width: "100%", borderRadius: "5px" }}
                        inputStyle={{
                          border: "1px solid gray",
                          backgroundColor: "#fff",
                          height: "40px",
                          width: "10%",
                          margin: "0px",
                          borderRadius: "5px",
                        }}
                        autoSelect
                      />
                    )}
                  />
                  <div
                    style={{ fontSize: "12px", cursor: "pointer" }}
                    className="d-flex pt-1 justify-content-end"
                  >
                    <label className="d-flex gap-1 align-items-center">
                      <input
                        onChange={(e) => {
                          // mobileNumberCheckBox(e);
                          mobileCheckBox(e);
                        }}
                        type="checkbox"
                        style={{ width: "15px", height: "15px" }}
                      />
                      Also Same As Above
                    </label>
                  </div>
                </div>
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">E Mail</label>
                  </div>
                  <Controller
                    control={control}
                    name="subjectEmail"
                    render={(field) => (
                      <InputField
                        {...field}
                        visiterInfoCss="visiterInfoCss"
                        type="email"
                      />
                    )}
                  />
                </div>

                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Category
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="subjectCategory"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <select
                        className="visiterInfoCss"
                        onChange={onChange}
                        value={value}
                      >
                        <option value=" "> </option>
                        <option value="Visitor">Visitor</option>
                        <option value="Client">Client</option>
                        <option value="Perspective">paspective</option>
                        <option value="Business">Business</option>
                        <option value="Interview">Interview</option>

                        <option value="Other">Other</option>
                      </select>
                    )}
                  />
                </div>
                {subjectCategory === "Other" && (
                  <div className="visitorEntryInputContainer">
                    <div>
                      <label className="visitorInfoLabelContainer">
                        other Category
                      </label>
                    </div>
                    <Controller
                      control={control}
                      name="subjectOtherCategory"
                      render={(field) => (
                        <InputField
                          {...field}
                          visiterInfoCss="visiterInfoCss"
                          type="text"
                        />
                      )}
                    />
                  </div>
                )}
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Reference
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="subjectSourceForm"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <select
                        value={value}
                        className="visiterInfoCss"
                        onChange={onChange}
                      >
                        <option value=" "> </option>
                        <option value="JustDial">Just Dial</option>
                        <option value="YouTube">YouTube</option>
                        <option value="Google">Google</option>
                        <option value="Advertisement">Advertisement</option>
                        <option value="SocialMedia">Social Media</option>
                        <option value="Referred">Referred By</option>
                        <option value="Other">Other</option>
                      </select>
                    )}
                  />
                </div>
                {subjectSourceForm === "Other" && (
                  <div className="visitorEntryInputContainer">
                    <div>
                      <label className="visitorInfoLabelContainer">
                        Other Reference
                      </label>
                    </div>
                    <Controller
                      control={control}
                      name="subjectOtherSourceForm"
                      render={(field) => (
                        <InputField
                          {...field}
                          visiterInfoCss="visiterInfoCss"
                          type="text"
                        />
                      )}
                    />
                  </div>
                )}
                {subjectSourceForm === "SocialMedia" && (
                  <div className="visitorEntryInputContainer">
                    <div>
                      <label className="visitorInfoLabelContainer">
                        Social Media Name
                      </label>
                    </div>
                    <Controller
                      control={control}
                      name="subjectOtherSourceForm"
                      render={(field) => (
                        <InputField
                          {...field}
                          visiterInfoCss="visiterInfoCss"
                          type="text"
                        />
                      )}
                    />
                  </div>
                )}
                {subjectSourceForm === "Referred" && (
                  <div className="visitorEntryInputContainer">
                    <div>
                      <label className="visitorInfoLabelContainer">
                        Reference By
                      </label>
                    </div>
                    <Controller
                      control={control}
                      name="subjectOtherSourceForm"
                      render={(field) => (
                        <InputField
                          {...field}
                          visiterInfoCss="visiterInfoCss"
                          type="text"
                        />
                      )}
                    />
                  </div>
                )}
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Vehicle Details
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="vehicleDetails"
                    render={(field) => (
                      <InputField
                        {...field}
                        visiterInfoCss="visiterInfoCss"
                        type="text"
                      />
                    )}
                  />
                </div>
                <div
                  className="visitorEntryInputContainer"
                  // style={{ width: "18%", height: "50px" }}
                >
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Additional Status
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="subjectAdditionalStatus"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <textarea
                        ref={ref}
                        style={{ height: "40px", width: "100%" }}
                        value={value}
                        className="visiterInfoCss  py-2"
                        onFocus={() =>
                          handleFocus(
                            "subjectAdditionalStatus",
                            "Additional Status",
                            value
                          )
                        }
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                        onBlur={onBlur}
                        disabled={isModalOpen}
                      ></textarea>
                    )}
                  />
                </div>
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Present Address
                    </label>
                  </div>

                  <Controller
                    control={control}
                    name="subjectTemporaryAddress"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <textarea
                        style={{ minHeight: "40px" }}
                        value={value}
                        className="visiterInfoCss"
                        onChange={onChange}
                      ></textarea>
                    )}
                  />
                </div>

                <div className="visitorEntryInputContainer">
                  <div
                    className="visitorInfoShowBtn fs-6 px-2 text-center"
                    style={{ color: "white", cursor: "pointer" }}
                    onClick={() => {
                      setShowPermanentAdd(true);
                    }}
                  >
                    Office Address & Permanent Address
                  </div>
                </div>
              </div>
              {/* <div className="visitorEntryFormInput py-5">
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      File Number
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="fileNumber"
                    render={(field) => (
                      <InputField
                        visiterInfoCss="visiterInfoCss"
                        {...field}
                        type="text"
                      />
                    )}
                  />
                </div>
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Client Name
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="subjectClientName"
                    render={(field) => (
                      <InputField
                        visiterInfoCss="visiterInfoCss"
                        {...field}
                        type="text"
                      />
                    )}
                  />
                </div>
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">Subject</label>
                  </div>
                  <Controller
                    control={control}
                    name="subject"
                    render={(field) => (
                      <InputField
                        visiterInfoCss="visiterInfoCss"
                        {...field}
                        type="text"
                      />
                    )}
                  />
                </div>
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Case Number
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="subjectCaseNumber"
                    render={(field) => (
                      <InputField
                        visiterInfoCss="visiterInfoCss"
                        {...field}
                        type="text"
                      />
                    )}
                  />
                </div>
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Mobile Number
                    </label>
                  </div>
                  <Controller
                    key={
                      alsoWhatApp ? "whatsapp-synced" : "whatsapp-independent"
                    }
                    control={control}
                    // name={`subjectDetails.${index}.subjectMobileNumber`}
                    name="subjectMobileNumber"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <PinInput
                        length={10}
                        initialValue={value ? value : ""}
                        onChange={onChange}
                        type="numeric"
                        // inputMode="number"
                        style={{ width: "100%", borderRadius: "5px" }}
                        inputStyle={{
                          border: "1px solid gray",
                          backgroundColor: "#fff",
                          height: "40px",
                          width: "10%",
                          margin: "0px",
                          borderRadius: "5px",
                        }}
                        // inputFocusStyle={{ backgroundColor: "#002147", }}
                        onComplete={(value, index) => {}}
                        autoSelect={true}
                      />
                    )}
                  />
                </div>
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Movable Properties
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="subjectMovableProperties"
                    render={(field) => (
                      <InputField
                        visiterInfoCss="visiterInfoCss"
                        {...field}
                        type="text"
                      />
                    )}
                  />
                </div>
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">Caste</label>
                  </div>
                  <Controller
                    control={control}
                    name="subjectCaste"
                    render={(field) => (
                      <InputField
                        visiterInfoCss="visiterInfoCss"
                        {...field}
                        type="text"
                      />
                    )}
                  />
                </div>
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Religion
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="subjectReligion"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <select
                        value={value}
                        className="visiterInfoCss py-2"
                        onChange={onChange}
                        onBlur={onBlur} // Added onBlur for better form validation handling
                        ref={ref} // Make sure the ref is passed down for proper field tracking
                      >
                        <option value=""> </option>
                        <option value="Christianity">Christianity</option>
                        <option value="Islam">Islam</option>
                        <option value="Hinduism">Hinduism</option>
                        <option value="Other">Other</option>
                      </select>
                    )}
                  />
                </div>
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Education
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="subjectEducation"
                    render={(field) => (
                      <InputField
                        visiterInfoCss="visiterInfoCss"
                        {...field}
                        type="text"
                      />
                    )}
                  />
                </div>
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Employment
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="subjectEmployment"
                    render={(field) => (
                      <InputField
                        visiterInfoCss="visiterInfoCss"
                        {...field}
                        type="text"
                      />
                    )}
                  />
                </div>

                <div
                  className="visitorEntryInputContainer"
                  style={{ width: "18%" }}
                >
                  <div>
                    <label className="visitorInfoLabelContainer">Address</label>
                  </div>
                  <Controller
                    control={control}
                    name="SubjectAddress"
                    render={({ field: { value, onChange, ref } }) => (
                      <textarea
                        ref={ref}
                        value={value || ""}
                        style={{ height: "40px", width: "100%" }}
                        className="visiterInfoCss py-2"
                        onFocus={() =>
                          handleFocus("SubjectAddress", "Address", value)
                        }
                        onChange={onChange}
                        disabled={isModalOpen}
                      />
                    )}
                  />
                </div>
                <div style={{ width: "18%" }}></div>
              </div> */}
            </div>
            <div className="" style={{ width: "100%" }}>
              <div className="headerContainer">
                <header className="visitorEntryHeader">
                  {" "}
                  Case Registration
                </header>
              </div>
              <div className="visitorEntryFormInput py-5">
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Case Number
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="caseDetailsName"
                    render={(field) => (
                      <InputField
                        {...field}
                        visiterInfoCss="visiterInfoCss"
                        type="text"
                      />
                    )}
                  />
                </div>
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">Date</label>
                  </div>
                  <Controller
                    control={control}
                    name="caseDetailsDate"
                    render={({ field }) => (
                      <DatePicker
                        dateFormat="dd/MM/yyyy eeee" // Correct format
                        className="visiterInfoCss"
                        selected={field.value}
                        onChange={(date) => field.onChange(date)}
                        isClearable
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={15}
                      />
                    )}
                  />
                </div>
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Nature of Case (Title)
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="caseDetailsNatureOfCaseTitle"
                    render={(field) => (
                      <InputField
                        {...field}
                        visiterInfoCss="visiterInfoCss"
                        type="text"
                      />
                    )}
                  />
                </div>
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      (Client’s Requirement)
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="natureOfCaseClientRequirement"
                    render={(field) => (
                      <InputField
                        {...field}
                        visiterInfoCss="visiterInfoCss"
                        type="text"
                      />
                    )}
                  />
                </div>

                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Nature of Case (Details)
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="natureOfCareDetails"
                    render={({ field: { value, onChange, ref } }) => (
                      <textarea
                        ref={ref}
                        value={value || ""}
                        style={{ height: "40px", width: "100%" }}
                        className="visiterInfoCss py-2"
                        onFocus={() =>
                          handleFocus(
                            "natureOfCareDetails",
                            "Nature of Care Details",
                            value
                          )
                        }
                        onChange={onChange}
                        disabled={isModalOpen}
                      />
                    )}
                  />
                </div>
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Date of Regards
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="subjectDateRegards"
                    render={({ field }) => (
                      <DatePicker
                        dateFormat="dd/MM/yyyy eeee" // Correct format
                        className="visiterInfoCss"
                        selected={field.value}
                        onChange={(date) => field.onChange(date)}
                        isClearable
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={15}
                      />
                    )}
                  />
                </div>
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Date of Closed
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="subjectDateClose"
                    render={({ field }) => (
                      <DatePicker
                        dateFormat="dd/MM/yyyy eeee" // Correct format
                        className="visiterInfoCss"
                        selected={field.value}
                        onChange={(date) => field.onChange(date)}
                        isClearable
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={15}
                      />
                    )}
                  />
                </div>
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Reference
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="reference"
                    render={(field) => (
                      <InputField
                        {...field}
                        visiterInfoCss="visiterInfoCss"
                        type="text"
                      />
                    )}
                  />
                </div>
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Name of (IOS)
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="referenceNameOfLos"
                    render={(field) => (
                      <InputField
                        {...field}
                        visiterInfoCss="visiterInfoCss"
                        type="text"
                      />
                    )}
                  />
                </div>
                <div
                  className="visitorEntryInputContainer"
                  style={{ width: "18%" }}
                >
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Reason of Closer
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="reasonForClosure"
                    render={({ field: { value, onChange, ref } }) => (
                      <textarea
                        ref={ref}
                        value={value || ""}
                        style={{ height: "40px", width: "100%" }}
                        className="visiterInfoCss py-2"
                        onFocus={() =>
                          handleFocus(
                            "reasonForClosure",
                            "Reason of Closed",
                            value
                          )
                        }
                        onChange={onChange}
                        disabled={isModalOpen}
                      />
                    )}
                  />
                </div>

                {/* <div className="visitorEntryInputContainer ">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Case Details
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="referenceCaseDetails"
                    render={({ field: { value, onChange, ref } }) => (
                      <textarea
                        ref={ref}
                        value={value || ""}
                        style={{ height: "40px", width: "100%" }}
                        className="visiterInfoCss py-2"
                        onFocus={() =>
                          handleFocus(
                            "referenceCaseDetails",
                            " Case Details",
                            value
                          )
                        }
                        onChange={onChange}
                        disabled={isModalOpen}
                      />
                    )}
                  />
                </div> */}
                <div className="visitorEntryInputContainer ">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Confidential Report
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="referenceConfReport"
                    render={({ field: { value, onChange, ref } }) => (
                      <textarea
                        ref={ref}
                        value={value || ""}
                        style={{ height: "40px", width: "100%" }}
                        className="visiterInfoCss py-2"
                        onFocus={() =>
                          handleFocus(
                            "referenceConfReport",
                            "Confidential Report",
                            value
                          )
                        }
                        onChange={onChange}
                        disabled={isModalOpen}
                      />
                    )}
                  />
                </div>
                {/* </div> */}
              </div>
            </div>
            {/* <div className="" style={{ width: "100%" }}>
              <div className="headerContainer">
                <header className="visitorEntryHeader">
                  {" "}
                  Reference Details
                </header>
              </div>
              <div className="visitorEntryFormInput py-5">
                
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">Date</label>
                  </div>
                  <Controller
                    control={control}
                    name="referenceData"
                    render={({ field }) => (
                      <DatePicker
                        dateFormat="eeee dd/MM/yyyy" // Correct format
                        className="visiterInfoCss"
                        selected={field.value}
                        onChange={(date) => field.onChange(date)}
                        isClearable
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={15}
                      />
                    )}
                  />
                </div>
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      File Number
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="referenceFileNumber"
                    render={(field) => (
                      <InputField
                        visiterInfoCss="visiterInfoCss"
                        {...field}
                        type="text"
                      />
                    )}
                  />
                </div>
                <div className="visitorEntryInputContainer">
                  <div>
                    <label className="visitorInfoLabelContainer">
                      Reason for Closure
                    </label>
                  </div>
                  <Controller
                    control={control}
                    name="referenceReasonForClosure"
                    render={(field) => (
                      <InputField
                        visiterInfoCss="visiterInfoCss"
                        {...field}
                        type="text"
                      />
                    )}
                  />
                </div>
                <div style={{ width: "100%",display:"flex",gap:"28px" }}>

                 

                </div>
              </div>
            </div> */}
            {userData === "superAdmin" && (
              <div style={{ width: "100%" }}>
                <div className="headerContainer">
                  <header className="visitorEntryHeader">
                    {" "}
                    Professional Payment Particular
                  </header>
                </div>
                <div className="visitorEntryFormInput py-5">
                  <div className="visitorEntryInputContainer ">
                    <div>
                      <label className="visitorInfoLabelContainer">
                        Total Amount
                      </label>
                    </div>
                    <Controller
                      control={control}
                      name="caseDetailsTotalAmount"
                      render={(field) => (
                        <InputField
                          visiterInfoCss="visiterInfoCss"
                          {...field}
                          type="number"
                        />
                      )}
                    />
                  </div>
                  <div className="visitorEntryInputContainer ">
                    <div>
                      <label className="visitorInfoLabelContainer">
                        Amount Received
                      </label>
                    </div>
                    <Controller
                      control={control}
                      name="caseDetailsAmountReceived"
                      render={(field) => (
                        <InputField
                          visiterInfoCss="visiterInfoCss"
                          {...field}
                          type="number"
                        />
                      )}
                    />
                    {amtError && <p style={{ color: "red" }}>{amtError}</p>}
                  </div>

                  <div className="visitorEntryInputContainer">
                    <div>
                      <label className="visitorInfoLabelContainer">
                        Mode of payment
                      </label>
                    </div>
                    <Controller
                      control={control}
                      name="caseDetailsPaymentType"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <select
                          value={value}
                          className="visiterInfoCss  py-2"
                          onChange={onChange}
                        >
                          <option value=" "> </option>
                          <option value=" Cash">Cash </option>
                          <option value=" Card">Card </option>
                          <option value="Online">Online</option>
                        </select>
                      )}
                    />
                  </div>
                  <div className="visitorEntryInputContainer ">
                    <div>
                      <label className="visitorInfoLabelContainer">
                        Balance Pending
                      </label>
                    </div>
                    <Controller
                      control={control}
                      name="caseDetailsBalancePending"
                      render={(field) => (
                        <InputField
                          visiterInfoCss="visiterInfoCss"
                          {...field}
                          type="number"
                        />
                      )}
                    />
                  </div>
                  <div className="visitorEntryInputContainer">
                    <div>
                      <label className="visitorInfoLabelContainer">Date</label>
                    </div>
                    <Controller
                      control={control}
                      name="caseDetailsAmountDate"
                      render={({ field }) => (
                        <DatePicker
                          dateFormat="dd/MM/yyyy eeee" // Correct format
                          className="visiterInfoCss"
                          selected={field.value}
                          onChange={(date) => field.onChange(date)}
                          isClearable
                          showYearDropdown
                          scrollableYearDropdown
                          yearDropdownItemNumber={15}
                        />
                      )}
                    />
                  </div>
                  {/* <div style={{ width: "100%",display:"flex",gap:"33px" }}> */}
                  <div className="visitorEntryInputContainer ">
                    <div>
                      <label className="visitorInfoLabelContainer">
                        Status
                      </label>
                    </div>
                    <Controller
                      control={control}
                      name="caseDetailsStatus"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <select
                          value={value}
                          className="visiterInfoCss  py-2"
                          onChange={onChange}
                        >
                          <option value=" "> </option>
                          <option value="pending">Pending</option>
                          <option value="completed">Completed</option>
                        </select>
                      )}
                    />
                  </div>
                  <div className="visitorEntryInputContainer ">
                    <div>
                      <label className="visitorInfoLabelContainer">
                        Chief comment
                      </label>
                    </div>
                    <Controller
                      control={control}
                      name="chiefComment"
                      render={({ field: { value, onChange, ref } }) => (
                        <textarea
                          ref={ref}
                          value={value || ""}
                          style={{ height: "40px", width: "100%" }}
                          className="visiterInfoCss py-2"
                          onFocus={() =>
                            handleFocus("chiefComment", "Chief comment", value)
                          }
                          onChange={onChange}
                          disabled={isModalOpen}
                        />
                      )}
                    />
                  </div>
                </div>
                <div>
                  <table
                    style={{
                      borderCollapse: "collapse",
                      width: "100%",
                    }}
                  >
                    {state && (
                      <thead>
                        <tr>
                          <th className="visitorAddAmtDetails">
                            Balance Pending
                          </th>
                          <th className="visitorAddAmtDetails">
                            Amount Received
                          </th>
                          <th className="visitorAddAmtDetails">Date Of Pay</th>
                          <th className="visitorAddAmtDetails">Status</th>
                        </tr>
                      </thead>
                    )}
                    <tbody>
                      {state &&
                        state?.caseDetails?.balancePending.map((ele, i) => {
                          if (state?.caseDetails?.amountReceived[i] === null) {
                            return null;
                          }
                          return (
                            <tr key={i}>
                              <td className="visitorAddAmtDetails">
                                {state?.caseDetails?.balancePending[i]}
                              </td>
                              <td className="visitorAddAmtDetails">
                                {state?.caseDetails?.amountReceived[i]}
                              </td>
                              <td className="visitorAddAmtDetails">
                                {moment(state?.caseDetails?.amountDate[i])
                                  .utc()
                                  .format("DD-MM-YYYY")}
                              </td>
                              <td className="visitorAddAmtDetails">
                                {state?.caseDetails?.status[i]}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            <div className="d-flex justify-content-center">
              <button
                onClick={() => {
                  console.log("hello");
                }}
                type="submit"
                className="visitorInfoSaveBtn p-2 px-4"
              >
                {isLoading ? (
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </form>
        </div>
      </section>
      <Alert
        open={postAlert}
        setPutAlert={setPutAlert}
        postAlert={postAlert}
        state={state}
        upData={upData}
        putAlert={putAlert}
        mutate={mutate}
        navigate={navigate}
        id={state?.id}
        add={true}
        onCloses={setPostAlert}
      />
      {/* <Modal
        show={isModalOpen}
        onClose={handleCloseModal}
        onSave={handleSave}
        value={watch("natureOfCareDetails")}
        currentField={currentField}
      /> */}
      {isModalOpen && currentField && (
        <Modal
          show={isModalOpen}
          onClose={() => setModalOpen(false)}
          onSave={handleSave}
          value={currentField.value} // Pass current value to the modal
          currentField={currentField}
        />
      )}
      <AddressModal isOpen={showPermanentAdd !== null}>
        {console.log("hello", showPermanentAdd)}
        <div className="mb-3 text-end">
          <img
            onClick={() => {
              setShowPermanentAdd(null);
            }}
            src={wrongIcon}
            style={{
              backgroundColor: "black",
              height: "30px",
              borderRadius: "8px",
            }}
            alt=""
          />
        </div>
        <div className="d-flex gap-5">
          <div className="" style={{ width: "50%", display: "" }}>
            <header
              className="fs-5 fw-bold text-center m-0 visitorInfoShowBtn py-2"
              style={{ color: "#fff" }}
            >
              Office Address Details
            </header>
            <div
              className="visitorEntryInputContainer my-2"
              style={{ width: "100%" }}
            >
              <div>
                <label className="visitorInfoLabelContainer">
                  Office Address
                </label>
              </div>

              <Controller
                control={control}
                name="subjectOfficeAddress"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <textarea
                    // style={{ minHeight: "100px" }}
                    value={value}
                    className="visitorAddCss py-2"
                    onChange={onChange}
                  ></textarea>
                )}
              />
            </div>
            <div className="my-2">
              <div>
                <label className="visitorInfoLabelContainer">
                  Office Address Mobile No
                </label>
              </div>
              <Controller
                control={control}
                name="subjectOfficeAddNo"
                render={(field) => (
                  <InputField
                    visiterInfoCss="visiterInfoCss"
                    {...field}
                    type="number"
                  />
                )}
              />
            </div>

            <div className="my-2">
              <div>
                <label className="visitorInfoLabelContainer">
                  Office Address Mail id
                </label>
              </div>
              <Controller
                control={control}
                name="subjectOfficeAddEmail"
                render={(field) => (
                  <InputField
                    visiterInfoCss="visiterInfoCss"
                    {...field}
                    type="email"
                  />
                )}
              />
            </div>

            <div>
              <div>
                <label className="visitorInfoLabelContainer">
                  Office Additional Details
                </label>
              </div>
              <Controller
                control={control}
                name="subjectOfficeAdditionalDetails"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <textarea
                    style={{ minHeight: "100px" }}
                    value={value}
                    className="visitorAddCss py-2"
                    onChange={onChange}
                  ></textarea>
                )}
              />
            </div>
          </div>
          {/* </div> */}

          <div style={{ width: "50%", display: "" }}>
            <header
              className="fs-5 fw-bold text-center m-0 visitorInfoShowBtn py-2"
              style={{ color: "#fff" }}
            >
              Permanent Address Details
            </header>
            <div
              className="visitorEntryInputContainer my-2"
              style={{ width: "100%" }}
            >
              <div>
                <label className="visitorInfoLabelContainer">
                  Permanent Address
                </label>
              </div>

              <Controller
                control={control}
                name="subjectPermanentAddress"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <textarea
                    // style={{ minHeight: "100px" }}
                    value={value}
                    className="visitorAddCss py-2"
                    onChange={onChange}
                  ></textarea>
                )}
              />
            </div>
            <div>
              <div>
                <label className="visitorInfoLabelContainer ">
                  Permanent Add Mobile No
                </label>
              </div>
              <Controller
                control={control}
                name="subjectPermanentAddNo"
                render={(field) => (
                  <InputField
                    visiterInfoCss="visiterInfoCss"
                    {...field}
                    type="number"
                  />
                )}
              />
            </div>
            <div className="my-2">
              <div>
                <label className="visitorInfoLabelContainer ">
                  Permanent Address Mail id
                </label>
              </div>
              <Controller
                control={control}
                name="subjectPermanentAddEmail"
                render={(field) => (
                  <InputField
                    visiterInfoCss="visiterInfoCss"
                    {...field}
                    type="email"
                  />
                )}
              />
            </div>
            <div className="my-2">
              <div>
                <label className="visitorInfoLabelContainer">
                  Permanent Additional Details
                </label>
              </div>
              <Controller
                control={control}
                name="subjectPermanentAdditionalDetails"
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <textarea
                    style={{ minHeight: "100px" }}
                    value={value}
                    className="visitorAddCss py-2"
                    onChange={onChange}
                  ></textarea>
                )}
              />
            </div>
          </div>
        </div>
        <div className="text-center">
          <button
            onClick={() => {
              setShowPermanentAdd(null);
            }}
            className="visitorInfoSaveBtn fs-3 px-3"
          >
            Save
          </button>
        </div>
      </AddressModal>
    </div>
  );
}
