import React, { useState, useEffect } from "react";

function Modal({
  show,
  onClose,
  onSave,
  currentField,
  value,
  showAddModalOpen,
}) {
  const [inputValue, setInputValue] = useState(value);
  console.log("modal", showAddModalOpen);

  useEffect(() => {
    setInputValue(value); // Update input value when modal opens or value changes
  }, [value]);

  if (!show) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 100,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={onClose}
    >
      <div
        style={{
          backgroundColor: "white",
          padding: "20px",
          width: "70%",
          height: "70vh",
          borderRadius: "5px",
          position: "relative",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <h6 className="py-2">{currentField?.labelName}</h6>
        <textarea
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          style={{ height: "83%", width: "100%" }}
          className="visitorAddCss py-2"
        />
        <div className="d-flex justify-content-lg-between align-items-center gap-2">
          <button
            onClick={() => onSave(inputValue)} // Save the input value to form state
            type="button"
            className="btn btn-primary"
          >
            Save
          </button>
          <button
            onClick={onClose} // Save the input value to form state
            type="button"
            className="btn btn-primary"
          >
            Back
          </button>
          <button
            // onClick={onClose} // Close modal without saving
            type="button"
            className="btn btn-secondary"
            onClick={() => {
              setInputValue("");
            }}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
}

export default Modal;
