import React from 'react'
import FileUpload from '../screens/FileUpload'
// import Sidebar from './Sidebar'
import SidebarMenu from './SidebarMenu'

export default function FileUploadComponent() {
  return (
    <section>
    <div
      className="container-fluid"
      style={{ overflow: "hidden", height: "100vh" }}
    >
      <div className="row p-0">
        <div className="col-lg-2 p-0">
          <SidebarMenu />
        </div>
        <div className="col-lg-10  p-0">
          <div className="row ">
            <div
              className="col-lg-12 "
              style={{ position: "relative", height: "100vh" }}
            >
              <FileUpload />
              {/* <Loading/> */}
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}
