import React, { useEffect, useState } from "react";

export default function AddressModal({ isOpen, onClose, children }) {
    if (!isOpen) return null;
    console.log("isOpen",isOpen);
    
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        // className="h-[200px] w-[400px] "
        style={{
          background: "white",
          height: "80%",
          width: 800,

          margin: "auto",
          padding: "10px 20px",
          // border: "2px solid #000",
          borderRadius: "10px",
          boxShadow: "2px solid black",
        }}
      >
        {children}
      </div>
    </div>
  );
}
