import Modal from "@mui/material/Modal";
import {
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
  DialogTitle,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import Images from "../asset";

export default function Alert(props) {
  // console.log(
  //   "props?.postAlert && props?.putAlert",
  //   props?.postAlert,
  //   props?.putAlert
  // );
  console.log("props----->", props);
  const handleOk = () => {
    console.log("props_postAlert", props?.putAlert);
    props?.setPutAlert(true);
    props?.onCloses(false);
    if (props?.postAlert && props?.state?.id) {
      console.log("put");
      props?.upData.mutate(props?.postAlert);
      // props?.navigate("/");
    } else if (props?.postAlert) {
      console.log("post");
      props?.mutate(props?.postAlert);
    }

    // props?.navigate("/")
  };
  return (
    <>
      {props?.add ? (
        <Dialog
          open={props?.open}
          onClose={() => props && props?.onCloses(false)}
        >
          <div style={{ backgroundColor: "#002147", width: "500px" }}>
            <DialogTitle>
              <div
                style={{
                  borderBottom: "2px solid #4E73DE",
                  paddingBottom: "10px",
                  marginBottom: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    fontSize: "18px",
                    fontWeight: "600px",
                    color: "#fff",
                    alignContent: "end",
                  }}
                >
                  Detective Agency
                </div>
                <div style={{ width: "7%", height: "7%" }}>
                  <img onClick={() => props?.onCloses(false)}
                    src={Images.closeIcon}
                    alt=""
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div style={{ width: "10%", height: "10%" }}>
                  <img
                    src={Images.InformationIcon}
                    alt=""
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
                <p
                  style={{ color: "#fff", margin: "0px", paddingLeft: "10px" }}
                >
                  {" "}
                  {/* {props?.id
                    ? "Information stored Update Successfully"
                    : "Information stored Successfully"} */}
                  {props?.id
                    ? "Are you sure want to update"
                    : "Are you sure want to submit"}
                </p>
              </div>
            </DialogTitle>

            <DialogActions
              style={{ display: "flex", justifyContent: "space-evenly" }}
            >
              <Button
                onClick={handleOk}
                style={{ background: "#fff", width: "150px" }}
              >
                YES
              </Button>
              <Button
                onClick={() => props?.onCloses(false)}
                style={{ background: "#fff", width: "150px" }}
              >
                NO
              </Button>
            </DialogActions>
            <DialogContent></DialogContent>
          </div>
        </Dialog>
      ) : (
        <Dialog
          open={props?.open}
          onClose={() => props && props?.onCloses(false)}
        >
          <div style={{ backgroundColor: "#002147", width: "500px" }}>
            <DialogTitle>
              <div
                style={{
                  borderBottom: "2px solid #4E73DE",
                  paddingBottom: "10px",
                  marginBottom: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    fontSize: "18px",
                    fontWeight: "600px",
                    color: "#fff",
                    alignContent: "end",
                  }}
                >
                  Detective Agency
                </div>
                <div style={{ width: "7%", height: "7%" }}>
                  <img
                  onClick={() => props?.onCloses(false)}
                    src={Images.closeIcon}
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div style={{ width: "10%", height: "10%" }}>
                  <img
                    src={Images.DeleteViewIcon}
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
                &nbsp;
                {/* <img /> */}
                <p style={{ color: "#fff", margin: "0px" }}>
                  {" "}
                  Are you sure to delete the User detail?{" "}
                </p>
              </div>
            </DialogTitle>

            <DialogActions
              style={{ display: "flex", justifyContent: "space-evenly" }}
            >
              <Button
                onClick={handleOk}
                style={{ background: "#fff", width: "150px" }}
              >
                YES
              </Button>
              <Button
                onClick={() => props?.onCloses(false)}
                style={{ background: "#fff", width: "150px" }}
              >
                NO
              </Button>
            </DialogActions>
            <DialogContent></DialogContent>
          </div>
        </Dialog>
      )}
    </>
  );
}
