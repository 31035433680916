import React from "react"
import searchIcon from "./searchIcon.png"
import notification from "./notification.png"
import search from "./searchIcon.png"
import logout from "./logout.png"
import deleteIC from "./delete.png"
import pencil from "./Pencil.png"
import adduser from "./adduser.png"
import SubmitApproval from "./SubmitApproval.png"
import Information from "./Information.png"
import DeleteView from "./DeleteView.png"
import loginlogo from "./loginlogo.png"
import closeIcon from "./close.png"
import arrowBackIcon from "./arrow-back.png"
const Images = {
    searchIcon: searchIcon,
    notificatioIcon: notification,
    logoutIcon: logout,
    searchIcon: search,
    deleteIcon: deleteIC,
    pencilIcon: pencil,
    adduserIcon: adduser,
    SubmitApprovalIcon: SubmitApproval,
    DeleteViewIcon: DeleteView,
    InformationIcon: Information,
    loginlogoIcon: loginlogo,
    closeIcon:closeIcon,
    arrowBack:arrowBackIcon,
}
export default Images