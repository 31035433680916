import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Images from "../asset";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import Loading from "./Loading";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import Select from "react-select";
import Dropdown from "react-multilevel-dropdown";
import moment from "moment/moment";
import Alert from "./Alert";
const VisitorListCom = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [dltId, setDltId] = useState();
  const [postAlert, setPostAlert] = useState();
  const [putAlert, setPutAlert] = useState();

  const [selectedItems, setSelectedItems] = useState([]); // Store selected items
  const [selectedValues, setSelectedValues] = useState({}); // Store selected values for each category

  // const ageSet = ["10/20","20/30"]
  const categories = [
    // { value: "name", label: "Name" },
    { value: "age", label: "Age" },
    { value: "caste", label: "Caste" },
    { value: "religion", label: "Religion" },
    { value: "category", label: "Category" },
  ];

  const visitorData = async () => {
    return await axios.get(`https://api.2kvirtualworld.com/DADEV/newvisitors`);
  };

  const { data, isLoading, isError, refetch } = useQuery(
    "visitorData",
    visitorData
  );
  const deleteUser = async (id) => {
    return await axios.delete(
      `https://api.2kvirtualworld.com/DADEV/newvisitors/${id}`
    );
  };

  // // Delete multiple users
  const deleteUsers = async (ids) => {
    return await Promise?.all(
      ids?.map((id) => {
        return deleteUser(id);
      })
    );
  };
  let details = (data && data?.data) || [];

  const handleCheckboxChange = (id) => {
    setSelectedRows((prevSelectedRows) => {
      const isSelected = prevSelectedRows?.includes(id);
      if (isSelected) {
        return prevSelectedRows?.filter((rowId) => rowId !== id);
      } else {
        return [...prevSelectedRows, id];
      }
    });
  };

  const mutation = useMutation({
    mutationFn: deleteUsers,
    onSuccess: () => {
      refetch();
      setPutAlert(false);
      setSelectedRows([]); // Clear selected rows
    },
    onError: (error) => {
      // console.error("Error deleting rows:", error);
      alert("Error deleting rows");
    },
  });
  const handleDelete = (id) => {
    setDltId(id);
    setPostAlert(true);
  };
  useEffect(() => {
    if (selectedRows?.length !== 0 && putAlert) {
      mutation.mutate(selectedRows);
    } else if (putAlert && dltId) {
      deleteUser(dltId)
        .then(() => {
          refetch();
          setDltId(null);
        })
        .catch((error) => {
          alert("Error deleting row");
        });
    }
  }, [selectedRows, putAlert, dltId]);

  // Filter details based on search term

  const filteredDetails = searchTerm
    ? details &&
      details?.filter((visitor) => {
        const term = searchTerm?.toLowerCase();
        return (
          // (visitor && visitor?.fileNumber?.toString().startsWith(term)) ||
          (visitor && visitor?.name?.toLowerCase().startsWith(term)) ||
          (visitor && visitor?.age?.toString().startsWith(term)) ||
          (visitor && visitor?.mobileNumber?.toString().startsWith(term)) ||
          (visitor && visitor?.email?.toLowerCase().startsWith(term)) ||
          (visitor && visitor?.permanentAddress?.toLowerCase().includes(term))
        );
      })
    : details;

  // const filteredData = filteredDetails?.filter((item) => {
  //   return Object.keys(selectedFilters)?.every((filterCategory) => {
  //     const filterValues = selectedFilters[filterCategory];
  //     const fieldValue = item?.[filterCategory];

  //     if (!filterValues || filterValues?.length === 0) return true;

  //     // Handle age range filtering
  //     if (filterCategory === "age") {
  //       return filterValues?.some((range) => {
  //         const [min, max] = range?.split("/").map(Number);
  //         const age = Number(fieldValue);
  //         return age >= min && age <= max;
  //       });
  //     }

  //     // Default filter logic for other categories
  //     return filterValues?.some(
  //       (value) =>
  //         fieldValue &&
  //         fieldValue?.toString().trim().toLowerCase() === value &&
  //         value?.toString().trim().toLowerCase()
  //     );
  //   });
  // });

  // Helper function to generate age range options
  const generateAgeOptions = () => {
    const ageRanges = [
      "0/18",
      "18/30",
      "30/40",
      "40/50",
      "50/60",
      "60/70",
      "70/80",
      "80/90",
      "90/99",
    ];

    return (
      ageRanges &&
      ageRanges?.map((range) => ({
        label: `${range}`,
        value: range,
      }))
    );
  };

  const generateOptions = (category) => {
    if (!category) return []; // Return empty if no category selected

    if (category === "age") {
      return generateAgeOptions(); // Return age options if category is "age"
    }

    const uniqueValues = [
      ...new Set(
        filteredDetails && filteredDetails?.map((item) => item[category])
      ),
    ];

    return (
      uniqueValues &&
      uniqueValues?.map((value) => ({
        label: value,
        value,
      }))
    );
  };
  // Handle item selection and removal
  const handleItemSelection = (selectedOption) => {
    setSelectedItems((prevItems) => {
      const isAlreadySelected =
        prevItems &&
        prevItems?.some(
          (item) =>
            item?.value === selectedOption?.value &&
            item?.label === selectedOption?.label
        );
      if (isAlreadySelected) {
        return (
          prevItems &&
          prevItems?.filter(
            (item) =>
              item?.value !== selectedOption?.value ||
              item?.label !== selectedOption?.label
          )
        ); // Remove item
      } else {
        return [...prevItems, selectedOption]; // Add item
      }
    });
  };
  // Handle category selection
  const handleCategorySelect = (categoryItem) => {
    console.log("Selected Category:", categoryItem); // Debugging log
  };
  // Handle value selection for category
  const handleValueSelect = (category, option) => {
    console.log("Selected Value:", option); // Debugging log
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [category]: option?.value, // Update the selected value for the specific category
    }));
    handleItemSelection(option); // Add item to selected items
  };

  // Handle removal of selected item
  // const handleRemoveItem = (item) => {
  //   setSelectedItems(
  //     (prevItems) =>
  //       prevItems &&
  //       prevItems?.filter(
  //         (i) => i?.value !== item?.value || i?.label !== item?.label
  //       )
  //   );

  //   setSelectedValues((prevValues) => {
  //     const updatedValues = { ...prevValues };
  //     const category = Object.keys(prevValues).find(
  //       (key) => prevValues[key] === item?.label
  //     );
  //     if (category) {
  //       delete updatedValues[category];
  //     }
  //     return updatedValues;
  //   });
  // };

  // Handle removal of selected item
  const handleRemoveItem = (item) => {
    setSelectedItems(
      (prevItems) =>
        prevItems &&
        prevItems?.filter(
          (i) => i?.value !== item?.value || i?.label !== item?.label
        )
    );

    setSelectedValues((prevValues) => {
      const updatedValues = { ...prevValues };
      const category = Object.keys(prevValues).find(
        (key) => prevValues[key] === item?.label
      );
      if (category) {
        delete updatedValues[category];
      }
      return updatedValues;
    });
  };
  // Filtering the data based on selected filters
  const filteredData =
    filteredDetails &&
    filteredDetails?.filter((row) => {
      return Object.keys(selectedValues)?.every((category) => {
        if (selectedValues[category]) {
          // Check if the selected category is "age"
          if (category === "age" && selectedValues[category]) {
            const ageRange = selectedValues[category]; // Get the selected age range
            const [minAge, maxAge] = ageRange.split("/").map(Number); // Split and convert to numbers
            const age = Number(row[category]); // Get the age from the row data

            // Check if the row's age is within the selected range
            return age >= minAge && age <= maxAge;
          }

          // Default filtering for other categories
          return row[category] === selectedValues[category];
        }
        return true;
      });
    });
  console.log("filteredData", filteredData, filteredDetails);

  const downloadExcel = () => {
    // Determine data to export based on filter
    const exportData =
      filteredData && filteredData?.length > 0 ? filteredData : filteredData;

    // Prepare data for the table worksheet
    const tableDatas =
      exportData && exportData?.length > 0
        ? exportData?.map((data, index) => ({
            "S.NO": index + 1,
            // "FILE NO": data?.fileNumber || "NULL",
            NAME: data?.name || "NULL",
            AGE: data?.age || "NULL",
            "MOBILE NUMBER": data?.mobileNumber || "NULL",
            "EMAIL ID": data?.email || "NULL",
            RELIGION: data?.religion || "NULL",
            CASTE: data?.caste || "NULL",
            CATEGORY: data?.category || "NULL",
            // "PLACE OF RESIDANCE": data?.placeOfResidence || "NULL",
            ADDRESS: data?.permanentAddress || "NULL",
            // "COUNTRY, STATE, DISTRICT":
            //   `${data?.country} ${data?.state} ${data?.district}` || "NULL",
            STATUS: data?.status || "NULL",
          }))
        : []; // Default to an empty array

    // Create table worksheet with headers
    const tableWorksheet = XLSX.utils.json_to_sheet(
      tableDatas?.length > 0 ? tableDatas : [{}],
      {
        skipHeader: false, // Ensure the header is included
      }
    );

    // If tableDatas is empty, add headers manually
    if (tableDatas?.length === 0) {
      XLSX.utils.sheet_add_aoa(tableWorksheet, [
        [
          "S.NO",
          // "FILE NO",
          "NAME",
          "AGE",
          "MOBILE NUMBER",
          "EMAIL ID",
          "RELIGION",
          "CASTE",
          "CATEGORY",
          // "PLACE OF RESIDANCE",
          // "ADDRESS",
          "COUNTRY ,STATE ,DISTRICT",
          "STATUS",
        ],
      ]);
    }

    // Create a new workbook and add the worksheets
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, tableWorksheet, "Table Data");

    // Generate the Excel file
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Save the Excel file
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "Visitor Information.xlsx");
  };
  return (
    <>
      <div className="visitor-main-container px-1 m-0">
        <div className="row">
          <div className="col-4 pt-3 pb-2 d-flex align-items-center gap-1">
            <div
              style={{ width: "50px", height: "40px", cursor: "pointer" }}
              onClick={() => navigate(-1)}
            >
              <img
                src={Images?.arrowBack}
                style={{ width: "100%", height: "100%" }}
                alt="back"
              />
            </div>
            <h6 className="client-view-head pt-2">Visitor Information</h6>
          </div>
          <div className="col-8 d-flex align-items-center justify-content-end gap-2">
            <div className="search-block-client-view">
              <input
                type="search"
                className="table-search"
                value={searchTerm}
                placeholder="Search by  name, age, mobNo, emailid, place"
                title="Search by  name, age, mobNo, emailid, place"
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <div className="image_icon">
                <img
                  src={Images?.searchIcon}
                  style={{ width: "100%", height: "100%" }}
                  alt="Search"
                />
              </div>
            </div>

            <Dropdown title="Filter">
              {categories &&
                categories?.map((categoryItem) => (
                  <Dropdown.Item
                    key={categoryItem?.value}
                    onClick={() => handleCategorySelect(categoryItem)}
                  >
                    {categoryItem?.label}
                    <Dropdown.Submenu>
                      {/* Display options for the selected category */}
                      {generateOptions(categoryItem?.value).map((option) => (
                        <Dropdown.Item
                          key={option?.value}
                          onClick={() =>
                            handleValueSelect(categoryItem?.value, option)
                          }
                        >
                          {option?.label}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Submenu>
                  </Dropdown.Item>
                ))}
            </Dropdown>
            <button
              className="new-register-btn --client-register"
              onClick={() => {
                downloadExcel();
              }}
            >
              Download
            </button>
            <button
              className="new-register-btn --client-register"
              onClick={() => {
                navigate("/visitorEntry");
              }}
            >
              New Visitor
            </button>
            {selectedRows == undefined || selectedRows?.length == 0 ? (
              <></>
            ) : (
              <>
                <button className="delete-btn" onClick={handleDelete}>
                  <img
                    src={Images?.deleteIcon}
                    alt={"deleteIcon"}
                    // style={{ width: "100%", height: "100%" }}
                  />
                  {/* {mutation.isLoading ? "Deleting..." : "DELETE"} */}
                </button>
              </>
            )}
          </div>
        </div>
        <div
          className="row px-3 m-0 p-0"
          style={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <div className="col-12 d-flex justify-content-end m-0 p-0">
            {selectedItems && selectedItems?.length > 0 ? (
              selectedItems?.map((item) => {
                return (
                  <>
                    <span
                      key={item?.value + item?.label}
                      style={{
                        // margin: "5px",
                        padding: "5px",
                        border: "1px solid #ccc",
                        display: "inline-flex",
                        alignItems: "center",
                      }}
                    >
                      {item?.label}
                      <button
                        onClick={() => handleRemoveItem(item)} // Remove item when clicked
                        style={{
                          marginLeft: "5px",
                          background: "red",
                          color: "white",
                          border: "none",
                          padding: "0px 6px",
                          cursor: "pointer",
                        }}
                      >
                        X
                      </button>
                    </span>
                  </>
                );
              })
            ) : (
              <>
                <p className="p-0 m-0 filter-content">
                  No categories selected. Please choose a category to proceed
                </p>
              </>
            )}
          </div>
        </div>

        <div className="register-table-block mb-1">
          <TableContainer className="product_table_container_sticky">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="product_table_heading">
                    Edit/Delete
                  </TableCell>
                  <TableCell className="product_table_heading">
                    Visitor's Count
                  </TableCell>
                  {/* <TableCell className="product_table_heading">
                    File No
                  </TableCell> */}
                  <TableCell className="product_table_heading">
                    Visitor's Name & Age with Credentials
                  </TableCell>
                  <TableCell className="product_table_heading">
                    Visitor's Mob No
                  </TableCell>
                  <TableCell className="product_table_heading">
                    {/* Name of Parents/Guardian/Spouse/Children/Others & Contact No/ */}
                    Email Id, if any
                  </TableCell>
                  <TableCell className="product_table_heading">
                    Visitor's Religion
                  </TableCell>
                  <TableCell className="product_table_heading">
                    Visitor's Caste
                  </TableCell>
                  <TableCell className="product_table_heading">
                    Visitor's Category
                  </TableCell>
                  {/* <TableCell className="product_table_heading">
                    Place Of Residence
                  </TableCell> */}
                  <TableCell className="product_table_heading">
                    Visitor's Address & Add1 Info
                  </TableCell>
                  {/* <TableCell className="product_table_heading">
                    State, Place
                  </TableCell> */}
                  <TableCell className="product_table_heading">
                    Visitor's Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <Loading />
                ) : filteredData && filteredData?.length > 0 ? (
                  filteredData?.map((item, i) => (
                    <TableRow key={i}>
                      <TableCell className="product_table_data">
                        <div className="d-flex align-items-center justify-content-center gap-1">
                          <div
                            style={{
                              width: "50px",
                              height: "30px",
                              cursor: "pointer",
                            }}
                          >
                            <img
                              src={Images?.pencilIcon}
                              alt="edit-icon"
                              style={{ width: "100%", height: "100%" }}
                              onClick={() => {
                                navigate("/visitorEntry", { state: item });
                              }}
                            />
                          </div>
                          <div className="d-flex align-items-center justify-content-center gap-1">
                            <input
                              type="checkbox"
                              checked={selectedRows?.includes(item?.id)}
                              onChange={() => handleCheckboxChange(item?.id)}
                              style={{
                                width: "20px",
                                height: "20px",
                                cursor: "pointer",
                              }}
                            />
                            <div
                              style={{
                                width: "27px",
                                height: "27px",
                                cursor: "pointer",
                              }}
                            >
                              <img
                                src={Images?.deleteIcon}
                                alt="edit-icon"
                                style={{ width: "100%", height: "100%" }}
                                onClick={() => handleDelete(item?.id)}
                              />
                            </div>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell className="product_table_data">
                        1 /{item && item?.duplicatevisitor?.length}
                        {console.log("item", item)}
                      </TableCell>
                      {/* <TableCell className="product_table_data">
                        {(item && item?.fileNumber) || "-"}
                      </TableCell> */}
                      <TableCell className="product_table_data">
                        {`${item && item?.name} & ${item && item?.age} ` || "-"}
                      </TableCell>
                      <TableCell className="product_table_data">
                        {(item && item?.mobileNumber) || "-"}
                      </TableCell>
                      <TableCell className="product_table_data">
                        {(item && item?.email) || "-"}
                      </TableCell>
                      <TableCell className="product_table_data">
                        {(item && item?.religion) || "-"}
                      </TableCell>
                      <TableCell className="product_table_data">
                        {(item && item?.caste) || "-"}
                      </TableCell>
                      <TableCell className="product_table_data">
                        {(item && item?.category) || "-"}
                      </TableCell>
                      {/* <TableCell className="product_table_data">
                        {(item && item?.placeOfResidence) || "-"}
                      </TableCell> */}
                      <TableCell className="product_table_data">
                        {(item && item?.permanentAddress) || "-"}
                      </TableCell>

                      {/* <TableCell className="product_table_data">
                        {` ${item && item?.state} ${item?.district}` || "-"}
                      </TableCell> */}

                      <TableCell className="product_table_data">
                        {(item && item?.status) || "-"}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={23}>No data available</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      <Alert
        open={postAlert}
        setPutAlert={setPutAlert}
        // postAlert={postAlert}
        // putAlert={putAlert}
        add={false}
        onCloses={setPostAlert}
        // setPutAlert={setPutAlert}
      />
    </>
  );
};

export default VisitorListCom;
