import React from "react";
// import Sidebar from '../component/Sidebar'
import FilterDataComponent from "../component/FilterDataComponent";
import SidebarMenu from "../component/SidebarMenu";

export default function FilterData() {
  return (
    <>
      <section className="clientViewMainSection p-0 m-0">
        <div className="container-fluid">
          <div className="row p-0 m-0">
            <div className="col-lg-12">
              <FilterDataComponent />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
