import React from 'react'
import VisitorInformationCom from '../component/VisitorInformationCom'

export default function VisitorInformation() {
  return (
    <div className='mainContainerDiv'>
    <VisitorInformationCom />
    </div>
  )
}
