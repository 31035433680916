import React, { useState } from "react";
import Images from "../asset";
import { useNavigate } from "react-router";
import ReactPaginate from "react-paginate";
import { useTable } from "react-table";
import MuiExcelUploadDialog from "../component/FileUploadModel";
import * as XLSX from "xlsx";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
import Loading from "../component/Loading";
import axios from "axios";
import { useQuery } from "react-query";
import Excel from "../asset/demo.xlsx"
export default function FileUpload() {
  const navigate = useNavigate();
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [statusValue, setStatusValue] = useState();
  const [currentPages, setCurrentPages] = useState(0);
  const [isModel, setIsModel] = useState(false);

  const columns = React.useMemo(
    () => [
      { Header: "S.NO", accessor: "S.NO" },
      { Header: "Name", accessor: "name" },
      { Header: "DATE & TIME", accessor: "DateTime" },
      { Header: "Type", accessor: "type" },
      { Header: "size", accessor: "size" },
    ],
    []
  );
  const fetchingData = async () => {
    return await axios.get(
      `https://api.2kvirtualworld.com/DADEV/visitorsfile/all`
    );
  };
  const { data, isLoading, isError, refetch } = useQuery(
    "fetchingData",
    fetchingData
  );
  const getFileUploadData = data && data?.data;
  console.log();
  // const filterdataFile =
  // getFileUploadData &&
  // getFileUploadData.filter((item) => {
  //     if (deleteIndex.length !== 0) {
  //       deleteIndex.includes(item?.id);
  //     }
  //   });
  const downloadExcel = () => {
    // Your data array
    const data = [
      {
        id: 4,
        fileId: "12345",
        agentName: "Agent A",
        pkl: "PKL001",
        comm: "Comment",
        received: "Yes",
        balanceAmount: 5000,
        date: "2024-08-22",
        partyName: "Party A",
        cbm: "CBM001",
        load: "Load001",
        paidAmount: "50025",
        status: "active",
      },
      // ... other data objects
    ];

    // Define headers
    const headers = [
      "S.no",
      "name",
      "mobileNumber",
      "category",
      "age",
      "fileNumber",
      "alternativeNumber",
      "sourceForm",
      "permanentAddress",
      "temporaryAddress",
      "officeAddress",
      "placeOfResidence",
      "district",
      "pincode",
      "inTime",
      "outTime",
      "state",
      "dateOfVisit",
      "dateOfAppoinment",
      "additionalStatus",
      "status",
      "email",
      "whatsapp",
      "caste",
      "relegion",
      "date",
    ];

    // Map data to match the headers order
    const formattedData =
      getFileUploadData.length !== 0
        ? getFileUploadData.map((item, index) => ({
          "S.no": index + 1,
          name: item?.name || "",
          mobileNumber: item?.mobileNumber || "",
          category: item?.category || "",
          age: item?.age || "",
          fileNumber: item?.fileNumber || "",
          alternativeNumber: item?.alternativeNumber || "",
          sourceForm: item?.sourceForm || "",
          permanentAddress: item?.permanentAddress || "",
          temporaryAddress: item?.temporaryAddress || "",
          officeAddress: item?.officeAddress || "",
          placeOfResidence: item?.placeOfResidence || "",
          pincode: item?.pincode || "",
          inTime: item?.inTime || "",
          outTime: item?.outTime || "",
          state: item?.state || "",
          dateOfVisit: item?.dateOfVisit || "",
          additionalStatus: item?.additionalStatus || "",
          status: item?.status || "",
          email: item?.email || "",
          whatsapp: item?.whatsapp || "",
          caste: item?.caste || "",
          relegion: item?.relegion || "",
          date: item?.date || "",
        }))
        : getFileUploadData?.length !== 0
          ? getFileUploadData &&
          getFileUploadData.map((item, index) => ({
            "S.no": index + 1,
            name: item?.name || "",
            mobileNumber: item?.mobileNumber || "",
            category: item?.category || "",
            age: item?.age || "",
            fileNumber: item?.fileNumber || "",
            alternativeNumber: item?.alternativeNumber || "",
            sourceForm: item?.sourceForm || "",
            permanentAddress: item?.permanentAddress || "",
            temporaryAddress: item?.temporaryAddress || "",
            officeAddress: item?.officeAddress || "",
            placeOfResidence: item?.placeOfResidence || "",
            pincode: item?.pincode || "",
            inTime: item?.inTime || "",
            outTime: item?.outTime || "",
            state: item?.state || "",
            dateOfVisit: item?.dateOfVisit || "",
            additionalStatus: item?.additionalStatus || "",
            status: item?.status || "",
            email: item?.email || "",
            whatsapp: item?.whatsapp || "",
            caste: item?.caste || "",
            relegion: item?.relegion || "",
            date: item?.date || "",
          }))
          : getFileUploadData
            ? getFileUploadData &&
            getFileUploadData.map((item, index) => ({
              "S.no": index + 1,
              name: item?.name || "",
              mobileNumber: item?.mobileNumber || "",
              category: item?.category || "",
              age: item?.age || "",
              fileNumber: item?.fileNumber || "",
              alternativeNumber: item?.alternativeNumber || "",
              sourceForm: item?.sourceForm || "",
              permanentAddress: item?.permanentAddress || "",
              temporaryAddress: item?.temporaryAddress || "",
              officeAddress: item?.officeAddress || "",
              placeOfResidence: item?.placeOfResidence || "",
              pincode: item?.pincode || "",
              inTime: item?.inTime || "",
              outTime: item?.outTime || "",
              state: item?.state || "",
              dateOfVisit: item?.dateOfVisit || "",
              additionalStatus: item?.additionalStatus || "",
              status: item?.status || "",
              email: item?.email || "",
              whatsapp: item?.whatsapp || "",
              caste: item?.caste || "",
              relegion: item?.relegion || "",
              date: item?.date || "",
            }))
            : getFileUploadData;
    getFileUploadData &&
      getFileUploadData.map((item, index) => ({
        "S.no": index + 1,
        name: item?.name || "",
        mobileNumber: item?.mobileNumber || "",
        category: item?.category || "",
        age: item?.age || "",
        fileNumber: item?.fileNumber || "",
        alternativeNumber: item?.alternativeNumber || "",
        sourceForm: item?.sourceForm || "",
        permanentAddress: item?.permanentAddress || "",
        temporaryAddress: item?.temporaryAddress || "",
        officeAddress: item?.officeAddress || "",
        placeOfResidence: item?.placeOfResidence || "",
        pincode: item?.pincode || "",
        inTime: item?.inTime || "",
        outTime: item?.outTime || "",
        state: item?.state || "",
        dateOfVisit: item?.dateOfVisit || "",
        additionalStatus: item?.additionalStatus || "",
        status: item?.status || "",
        email: item?.email || "",
        whatsapp: item?.whatsapp || "",
        caste: item?.caste || "",
        relegion: item?.relegion || "",
        date: item?.date || "",
      }));

    // Create a new workbook and a worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(formattedData, { header: headers });

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Generate Excel file and trigger download
    XLSX.writeFile(wb, "Data.xlsx");
  };

  // const data = [
  //   {
  //     name: "sample Data",
  //     DateTime: "25-10-2024",
  //     type: "microsoft excel",
  //     size: "124 KB",
  //   },
  //   {
  //     name: "sample Data",
  //     DateTime: "25-10-2024",
  //     type: "microsoft excel",
  //     size: "124 KB",
  //   },
  //   {
  //     name: "sample Data",
  //     DateTime: "25-10-2024",
  //     type: "microsoft excel",
  //     size: "124 KB",
  //   },
  // ];
  // const itemsPerPages = 9;
  // const pageCount = Math.ceil(getFileUploadData?.length / itemsPerPages);

  // console.log('pageCount',pageCount);

  // const handlePageClick = (event) => {
  //   setCurrentPages(event.selected);
  // };

  // const currentData = getFileUploadData?.slice(
  //   currentPages * itemsPerPages,
  //   (currentPages + 1) * itemsPerPages
  // );

  // const tableInstance = useTable({ columns, data: currentData });
  // const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
  //   tableInstance;

  const searchData = getFileUploadData?.filter((item, index) => {
    return item?.fileNumber.toString() === searchTerm;
  });
  console.log("serchData", searchData);

  return (
    <>
      <div className="headerTimeLine px-3 py-3">
        <div
          className="px-5 d-flex"
          style={{ justifyContent: "space-between" }}
        >
          <div
            className="d-flex search-block"
            style={{
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0px 10px",
            }}
          >
            <input
              type="search"
              className="table-search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="image_icon">
              <img
                src={Images.searchIcon}
                style={{ width: "100%", height: "%" }}
                alt="Search"
              />
            </div>
          </div>
          <div
            className="d-flex gap-2 align-items-center"
            style={{ width: "50%" }}
          >
            <div
              className="add-user-btn-block"
              onClick={() => navigate("/visitorAdd")}
            >
              <button className="new-user-btn">NEW VISITOR</button>
            </div>

            <a download="DeleteView.xlsx" href={Excel}
              className="add-user-btn-block"
              style={{ textDecoration: "none !important" }}
            // onClick={() => {
            //   downloadExcel();
            // }}
            >
              <button className="new-user-btn" style={{ textDecoration: "none !important" }}>DOWNLOAD SAMPLE FILE</button>
            </a>

            {/* <div className="add-user-btn-block-delete">
              <button
                className="new-user-btn"
                onClick={() => {
                  setPopupOpen(true);
                }}
                // disabled={mutation.isLoading}
              >
                <img
                  src={Images.deleteIcon}
                  alt={"deleteIcon"}
                  style={{ width: "35px", height: "%" }}
                />
              </button>
            </div> */}
          </div>
        </div>
      </div>
      <div className="table_form_page">
        <div className="d-flex justify-content-end pt-3 me-4">
          <div className="fileNewUpload" onClick={() => setIsModel(true)}>
            NEW EXCEL UPLOAD
          </div>
        </div>
        <div className="table-wrapper_fileUpload">
          <div className="table_fileUpload">
            {/* <table {...getTableProps()} className="table">
              <thead style={{ background: "#002147", border: "none" }}>
                {headerGroups.map((headerGroup) => (
                  <tr className="fileNameHeader"
                    style={{ background: "#002147", borderRadius: 10,}}
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column) => (
                      <th 
                        style={{
                          border: "none",
                          background: "#002147",
                          color: "#fff",
                          textAlign:"center"
                          // width:"150px"
                        }}
                        {...column.getHeaderProps()}
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr style={{ border: "none", width:"150px" }} {...row.getRowProps()}>
                      {row.cells.map((cell) => (
                        <td
                          style={{
                            border: "none",
                            borderBottom: "2px solid #000",
                            textAlign:"center"
                          }}
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table> */}
            <TableContainer
              sx={{ maxHeight: "600px" }}
              className="m-0 fileUpload_container_sticky"
            >
              <Table stickyHeader>
                <TableHead className="product_heading_sticky ">
                  <TableRow>
                    <TableCell
                      sx={{ backgroundColor: "#002147", top: 0, zIndex: 1 }}
                      className="product_table_heading "
                    >
                      S.No
                    </TableCell>
                    <TableCell
                      sx={{ backgroundColor: "#002147", top: 0, zIndex: 1 }}
                      className="product_table_heading"
                    >
                      File No
                    </TableCell>
                    <TableCell
                      sx={{ backgroundColor: "#002147", top: 0, zIndex: 1 }}
                      className="product_table_heading"
                    >
                      Date
                    </TableCell>
                    <TableCell
                      sx={{ backgroundColor: "#002147", top: 0, zIndex: 1 }}
                      className="product_table_heading"
                    >
                      Type
                    </TableCell>
                    <TableCell
                      sx={{ backgroundColor: "#002147", top: 0, zIndex: 1 }}
                      className="product_table_heading"
                    >
                      Size
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading ? (
                    <Loading />
                  ) : searchTerm ? (
                    searchData &&
                    searchData?.map((items, i) => {
                      {/* console.log("clientinformation", items); */ }
                      return (
                        <TableRow key={i}>
                          <TableCell className="product_table_data">
                            {i + 1}
                          </TableCell>
                          <TableCell className="product_table_data">
                            {items?.fileNumber}
                          </TableCell>
                          <TableCell className="product_table_data">
                            {items?.date}
                            {moment(items?.date).format("DD MM YYYY")}
                          </TableCell>
                          <TableCell className="product_table_data">
                            {/* {items?.name} */}
                            Excel
                          </TableCell>
                          <TableCell className="product_table_data">
                            {/* {items?.name} */}-
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : getFileUploadData && getFileUploadData ? (
                    getFileUploadData?.map((items, i) => {
                      console.log("clientinformation", items);
                      return (
                        <TableRow key={i}>
                          <TableCell className="product_table_data">
                            {i + 1}
                          </TableCell>
                          <TableCell className="product_table_data">
                            {items?.fileNumber}
                          </TableCell>
                          <TableCell className="product_table_data">
                            {moment(items?.date).format("DD MM YYYY")}
                          </TableCell>
                          <TableCell className="product_table_data">
                            {/* {items?.name} */}
                            Excel
                          </TableCell>
                          <TableCell className="product_table_data">
                            {/* {items?.name} */}-
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <>
                      <p
                        style={{
                          width: "300%",
                          color: "#002147",
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                      >
                        No Records Found
                      </p>
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
        {/* <div className="pagination-wrapper m-0">
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            activeClassName={"active"}
            pageRangeDisplayed={2}
            marginPagesDisplayed={1}
          />
        </div> */}
      </div>
      <div>
        <MuiExcelUploadDialog open={isModel} onClose={setIsModel} />
      </div>
    </>
  );
}
