import React, { useState } from "react";
import axios from "axios";
import companyLogo from "../asset/logo.jpg";
import LoginImg from "../asset/loginImg.jpg";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router";
import userIcon from "../asset/User.png";
import emailIcon from "../asset/Email.png";
import passwordIcon from "../asset/Lock.png";
import InputField from "../component/InputField";
import Images from "../asset";
import AlertModal from "../component/AlertModal";
import hatImg from "../asset/hatImg.png";
import binocular from "../asset/binocular.png";
import notPad from "../asset/notePad.png";
import whiteSheet from "../asset/whiteSheet.png";


export default function UserForgetPassword() {
  const { control, handleSubmit, setValue, watch, register } = useForm();
  const navigate = useNavigate();
  const [isenabel, setIsEnable] = useState(false);
  const [showError, setShowError] = useState();
  const [open, setOpen] = useState();

  const { mutate, isLoading } = useMutation(
    (postData) =>
      // axios.post(`${process.env.REACT_APP_HOS}/registers`, postData),
      axios.post(
        "https://api.2kvirtualworld.com/DADEV/registers/forgotpassword",
        postData
      ),
    {
      onSuccess: (data) => {
        setOpen(data && data?.data);
        // console.log("otpData",data&&data?.data);

        // navigate("/resetPassword")
        // sessionStorage.setItem("login", true);
        // window.location?.reload();
        // navigate("/");
        // alert("OTP SENT ")
      },
      onError: (error) => {
        console.error("Mutation failed:", error?.response.data);
        setShowError(error?.response.data);
      },
    }
  );
  //   const { email } = watch();
  const submit = (data) => {
    console.log("data", data);

    mutate(data);
  };

  return (
    <div
      className="userRegisterContainer container-fluid px-0 "
      style={{ overflow: "hidden",display:"flex" }}
    >
     <div
        className="loginNewBackImage"
        style={{
          width: "50%",
        }}
      >
        <div className="d-flex">
          <div className="loginHatImage">
            <img
              className="hatMove"
              style={{ height: "100%" }}
              src={hatImg}
              alt=""
            />
          </div>
          <div className="loginWhiteSheet">
            <img
              // className="loginWhiteSheet"
              style={{ height: "100%" }}
              src={whiteSheet}
              alt=""
            />
          </div>
        </div>
        <div className="loginBinocularImage">
          <img style={{ height: "100%" }} src={binocular} alt="" />
        </div>
        <div className="loginNotPadImage">
          <img
            style={{ height: "100%" }}
            className="notPadImage"
            src={notPad}
            alt=""
          />
        </div>
      </div>
      <div
        className="row vh-100"
        style={{
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width:"50%"
        }}
      >
        <div
          className="col-lg-9"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <div className="bg-white_loginform">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <img src={Images?.loginlogoIcon} alt="loginlogoIcon" />
            </div>
            <p
              className=""
              style={{
                textShadow: " 2px 2px #00000040",
                font: "Poppins",
                fontSize: "35px",
                textAlign: "center",
                fontWeight: "600",
              }}
            >
              Forget Password
            </p>
            {/* <p className="">Welcome Back,</p> */}
            <form className="row" onSubmit={handleSubmit(submit)}>
              <div>
                <div className="mb-4 userRegisterInputs --userRegisterInputs d-flex">
                  <img
                    className="userRegisterInputLogo"
                    src={userIcon}
                    alt=""
                  />
                  <Controller
                    control={control}
                    name="email"
                    render={(field) => (
                      <InputField
                        registerInputCss=" registerInputCss"
                        {...field}
                        type="text"
                        placeholder="E-Mail"
                      />
                    )}
                  />
                </div>

                <div className="userRegisterBtnContainer">
                  <button className="userRegisterBtn" type="submit">
                    {isLoading ? (
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      "GET OTP"
                    )}
                    {/* GET OTP */}
                  </button>
                </div>
              </div>
            </form>
            <div
              onClick={() => {
                navigate("/");
              }}
              className="text-end pe-5  text-decoration-underline userLoginNavigate"
            >
              Login?
            </div>
            <p className="mb-0 fs-5 fw-bold text-danger">{showError}</p>

            {/* <div className="userRegister_registerMainContainer userRegister_registerForm ">
          
            </div> */}
          </div>
        </div>
      </div>
      <AlertModal
        open={open}
        setOpen={setOpen}
        // reset={reset}
        // handleClose={handleClose}
        // setUpdateOpen={setUpdateOpen}
        // UpdateOpen={UpdateOpen}
        navigate={"/resetPassword"}
        // setErrorOpen={setErrorOpen}
        // errorOpen={errorOpen}
      />
    </div>
  );
}
